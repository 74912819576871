import { Button, Col, DatePicker, Row, Select, notification } from "antd";
import { Link } from "react-router-dom";
import { apis, paths } from "../../../constants";
import { Column, DualAxes } from "@ant-design/plots";
import { useEffect, useState } from "react";
import { Line } from "@ant-design/charts";
import DualAxesChart from "@ant-design/plots/es/components/dual-axes";
import AxiosService from "../../../common/AxiosService";
import styles from "./homePage.module.less";
import SystemMap from "./components/SystemMap";
import { useSelector } from "react-redux";
import { formatWeightText } from "../../../helpers";

const HomePage = () => {
  const auth = useSelector((state) => state.auth);
  const [data, set_data] = useState([]);
  const [steelType, set_steelType] = useState(1);
  useEffect(() => {
    initData();
  }, [auth.wareHouseName]);
  const initData = () => {
    AxiosService.post(apis.Url_Get_HomepageDataChart, {})
      .then((res) => {
        let { data } = res;
        if (!!data && data.message === "OK") {
          set_data(data.data);
        } else {
          // notification.error({message: data.message})
        }
      })
      .catch((e) => notification.error({ message: e.message }));
  };

  const config = {
    data,
    xField: "typeProduct",
    yField: "numberSave",
    label: {
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    maxColumnWidth: "20px",
    columnStyle: {},
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      typeProduct: {
        alias: "Loại sản phẩm",
      },
      numberSave: {
        alias: "Số lượng",
      },
    },
  };

  const configDual = {
    data,
    xField: "typeProduct",
    children: [
      {
        type: "interval",
        yField: "numberSave",
        style: { maxWidth: 60 },
        tooltip: (d) => ({
          name: "Số lượng",
          value: Number(d.numberSave ),
        }),
        axis: { title: "Số lượng", y: { position: "left" } },
        label: {
          formatter: (text, item, index) => {
            // console.log("text, item, index", text, item, index);
            return item.numberSave;
          },
          style: {
            textBaseline: 'bottom',
          }
        },
      },
      {
        type: "line",
        yField: "percentSave",
        style: {
          stroke: "#5AD8A6",
          lineWidth: 2,
        },
        tooltip: (d) => ({
          name: "Tỉ lệ",
          value: Math.round((d.percentSave + Number.EPSILON) * 100) / 100 + "%",
        }),
        axis: { title: "Tỉ lệ", y: { position: "right" } },
        label: {
          formatter: (text, item, index) => {
            // console.log("text, item, index", text, item, index);
            return (
              Math.round((item.percentSave + Number.EPSILON) * 100) / 100 + "%"
            );
          },
        },
      },
    ],
  };
  console.log(auth);
  return (
    <div className={styles["main-container"]}>
    <div className={styles["main"]}>
      <h1>Tổng quan</h1>
      {/* <div style={{ width: '60%', height: '500px' }}>

      <Column {...config} />
    </div> */}
      <Row align={"middle"}>
        <Col span={3}>Biểu đồ tồn kho</Col>
        <Col span={4}>
          {/* <Select
            className={styles["filter-select"]}
            onChange={set_steelType}
            defaultValue={1}
            value={steelType}
            showSearch
            optionFilterProp="label"
            dropdownStyle={{
              borderWidth: 1,
              borderColor: " rgba(0, 0, 0, 0.2)",
              backgroundColor: "white",
            }}
            options={[{ value: 1, label: "Kho tôn cuộn" }]}
          /> */}
        </Col>
        {/* <Col><Button>Tìm kiếm</Button></Col> */}
      </Row>
      {true && (
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ width: "60%", height: "500px" }}>
            <DualAxes {...configDual} />
          </div>
          <div className={styles["dashboard-table"]}>
            {!!data && (
              <Row style={{ width: "100%" }}>
              <Col span={8}><div  className={styles["table-cell"]}></div></Col>
              <Col span={8}><div  className={styles["table-cell"]}>Số lượng tồn kho (kg)</div></Col>
              <Col span={8}><div  className={styles["table-cell"]}>Tỉ lệ tồn kho</div></Col>
              {data.map((cur) => (
                    <>
                    <Col span={8}><div  className={styles["table-cell"]}>{cur.typeProduct}</div></Col>
                    <Col span={8}><div  className={styles["table-cell"]}> {formatWeightText(cur.numberSave)  }</div></Col>
                    <Col span={8}><div  className={styles["table-cell"]}> {Math.round((cur.percentSave + Number.EPSILON) * 100) /
                        100 +
                        "%"}</div></Col>
                     
                    </>
                  ))}
              </Row>
            )}
          </div>
        </div>
      )}

      <SystemMap />
    </div>
    </div>
  );
};

export default HomePage;
