import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Breadcrumb, Button, Col, Input, Space, Row, Table, Select, Pagination, notification, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import AxiosService from '../../../../common/AxiosService';
import { useDispatch } from 'react-redux';
import style from './QuyChuanBangXe.module.less';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import configs from '../../../../constants/configs';
import ModalEditQuyChuan from './ModalEditQuyChuan/ModalEditQuyChuan';
import { apis } from '../../../../constants';
import { appLoading, refetchMasterStandard } from '../../../../ducks/slices/appSlice';

// màn hình quy chuẩn cho loại thép thường
const QuyChuanBangXe = () => {
    const dispatch = useDispatch();
    const modalRef = useRef(null);

    const [modal, contextHolder] = Modal.useModal();

    // const {user_data} = useSelector(state => state.auth);

    const standardMaster = useSelector(state => state.app.listMasterStandard);
    const standardTable = useMemo(() => {
        if (!standardMaster) return [];
        return standardMaster.reduce((prev, next) => {
            var lsValue =
                next.lstBody.map(item => ({
                    typeProduct: next.label,
                    thicknessProduct: item.specificSize,
                    widthTape: item.sizeThickness,
                    headId: next.id,
                    bodyId: item.id,
                    numberBundles: next.numberBundles,
                    productFlg: '0',
                })) || [];
            return prev.concat(lsValue);
        }, []);
    }, [standardMaster]);
    //console.log('Standard table', standardTable);

    const [filterThick, set_filterThick] = useState('');
    const [filterType, set_filterType] = useState('');
    const [filterWidth, set_filterWidth] = useState('');

    //data source của table, filter theo 3 giá trị
    const [dataSource, set_dataSource] = useState([]);
    useEffect(() => {
        if (standardTable) set_dataSource(standardTable);
    }, [standardTable]);

    const [currentPage, set_currentPage] = useState(1);
    const [pageSize, set_pageSize] = useState(10);
    const onChangePage = val => {
        set_currentPage(val);
    };
    const onChangePageSize = val => {
        set_pageSize(val);
        set_currentPage(1);
    };
    // tìm kiếm giá trị
    const onFiltterData = () => {
        var newSource = standardTable.filter(
            i => (i.typeProduct.includes(filterType) || !filterType) && (i.thicknessProduct == filterThick || !filterThick) && (i.widthTape == filterWidth || !filterWidth),
        );
        set_currentPage(1);
        set_dataSource(newSource);
    };
    //xoá bộ lọc tìm kiếm trên
    const onClearFilter = () => {
        set_filterThick('');
        set_filterType('');
        set_filterWidth('');
        set_dataSource(standardTable);
        set_currentPage(1);
    };

    const columns = [
        {
            title: 'Loại sản phẩm (mm)',
            dataIndex: 'typeProduct',
            key: 'typeProduct',
            // width: '90px',
            align: 'center',
        },
        {
            title: 'Dày sp (mm)',
            dataIndex: 'thicknessProduct',
            key: 'thicknessProduct',
            // width: '90px',
            align: 'center',
        },
        // {
        //     title: 'Số lượng cây/bó',
        //     dataIndex: 'numberBundles',
        //     key: 'numberBundles',
        //     // width: '90px',
        //     align: 'center',
        // },
        {
            title: 'Rộng băng xẻ (mm)',
            dataIndex: 'widthTape',
            key: 'widthTape',
            // width: '90px',
            align: 'center',
        },

        {
            title: '',
            key: 'option',
            width: '120px',
            align: 'center',
            render: (value, record, index) => (
                <Space>
                    <div className={style['table-button']} onClick={() => modalRef.current?.openModal(record)}>
                        <EditOutlined style={{ color: '#2c3782', fontSize: '15px' }} />
                    </div>
                    <div style={{ width: '10px' }} />
                    <div className={style['table-button']} onClick={() => askBeforeDeleteValue(record)}>
                        <DeleteOutlined style={{ color: '#2c3782', fontSize: '15px' }} />
                    </div>
                </Space>
            ),
        },
    ];

    const [thickProduct, set_thickProduct] = useState('');
    const [typeProduct, set_typeProduct] = useState('');
    const [widthTape, set_widthTape] = useState('');
    const [submited, set_submited] = useState(false);

    const onSumbitNewValue = async () => {
        set_submited(true);
        if (!thickProduct || !typeProduct || !widthTape) return;
        //call api save new value
        const dataSend = {
            thicknessProduct: widthTape,
            typeProduct,
            specificSize: thickProduct,
            productFlg: '0',
        };
        dispatch(appLoading(true));
        try {
            const res = await AxiosService.post(apis.Url_Api_Create_Split_Tape_Standard, dataSend);
            if (res.data.status == 200) {
                dispatch(refetchMasterStandard());
                notification.success({ message: 'Thêm dữ liệu thành công' });
                set_thickProduct('');
                set_typeProduct('');
                set_widthTape('');
                set_submited(false);
            } else {
                notification.error({ message: 'Thêm dữ liệu thất bại' });
                console.log(res.data);
            }
        } catch (e) {
            notification.error({ message: 'Thêm dữ liệu thất bại' });
            console.log('Error:', e);
        } finally {
            dispatch(appLoading(false));
        }
    };

    const askBeforeDeleteValue = record => {
        modal.confirm({
            title: 'Xác nhận xóa',
            icon: <ExclamationCircleOutlined />,
            content: 'Bạn chắc chắn muốn xóa quy chuẩn này?',
            okText: 'Xóa',
            okType: 'danger',
            onOk: () => onDeleteValue(record),
            cancelText: 'Đóng',
        });
    };
    const onDeleteValue = async ({ headId, bodyId }) => {
        try {
            const res = await AxiosService.post(apis.Url_Api_Delete_Split_Tape_Standard, { headId, bodyId });
            if (res.data.status == 200) {
                dispatch(refetchMasterStandard());
                notification.success({ message: 'Xoá dữ liệu thành công' });
            } else {
                notification.error({ message: 'Xoá dữ liệu thất bại' });
                console.log(res.data);
            }
        } catch (e) {
            notification.error({ msg: 'Xoá dữ liệu thất bại' });
            console.log(e);
        }
    };
    return (
        <div className={style['main-container']}>
            <Breadcrumb
                items={[
                    {
                        title: 'Quản lý dữ liệu',
                    },
                    {
                        title: 'Khổ băng xẻ',
                    },
                ]}
            />
            <div className={style['main']}>
                <h3 style={{ color: 'black' }}>Thêm giá trị mới</h3>
                <Row className={style['row-input']}>
                    <Col span={2}>{`Loại sp (mm)`}</Col>
                    <Col span={4}>
                        <Input
                            className={cn(style['filter-input'], submited && typeProduct == '' && style['warning-input'])}
                            value={typeProduct}
                            onChange={({ target }) => set_typeProduct(target.value)}
                        />
                    </Col>
                    <Col span={2}>{`Dày sp (mm)`}</Col>
                    <Col span={4}>
                        <Input
                            className={cn(style['filter-input'], submited && thickProduct == '' && style['warning-input'])}
                            value={thickProduct}
                            onChange={({ target }) => set_thickProduct(target.value.replace(/[^0-9.]/g, ''))}
                        />
                    </Col>
                    <Col span={2}>{`Rộng băng xẻ (mm)`}</Col>
                    <Col span={4}>
                        <Input
                            className={cn(style['filter-input'], submited && widthTape == '' && style['warning-input'])}
                            value={widthTape}
                            onChange={({ target }) => set_widthTape(target.value.replace(/[^0-9.]/g, ''))}
                        />
                    </Col>
                    <Col span={2}>
                        <Button onClick={onSumbitNewValue} className={style['filter-button']}>
                            Nhập dữ liệu
                        </Button>
                    </Col>
                </Row>
                <h3 style={{ color: 'black', marginTop: '50px' }}>Giá trị đã lưu</h3>
                <Row className={style['row-input']}>
                    <Col span={2}>{`Loại sp (mm)`}</Col>
                    <Col span={4}>
                        <Input className={style['filter-input']} value={filterType} onChange={({ target }) => set_filterType(target.value)} />
                    </Col>
                    <Col span={2}>{`Dày sp (mm)`}</Col>
                    <Col span={4}>
                        <Input className={style['filter-input']} value={filterThick} onChange={({ target }) => set_filterThick(target.value.replace(/[^0-9.]/g, ''))} />
                    </Col>
                    <Col span={2}>{`Rộng băng xẻ (mm)`}</Col>
                    <Col span={4}>
                        <Input className={style['filter-input']} value={filterWidth} onChange={({ target }) => set_filterWidth(target.value.replace(/[^0-9.]/g, ''))} />
                    </Col>
                    <Col span={2}>
                        <Button onClick={onFiltterData} className={style['filter-button']} icon={<SearchOutlined style={{ fontSize: '16px', color: 'white' }} />}>
                            Tìm kiếm
                        </Button>
                    </Col>

                    <Col span={2} style={{ marginLeft: '15px' }}>
                        <Button onClick={onClearFilter} className={style['filter-button']}>
                            Xoá lọc
                        </Button>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    dataSource={dataSource.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
                    className={style['table']}
                    rowKey={'bodyId'}
                    scroll={{ x: '100%' }}
                    pagination={false}
                    rowClassName={style['table-row']}
                />
                <Row justify={'end'} className={style['pagination']} style={{ marginTop: '10px' }}>
                    <Pagination
                        locale={{ items_per_page: '' }}
                        pageSizeOptions={[5, 10, 20]}
                        className={style['pagination-bar']}
                        pageSize={pageSize}
                        hideOnSinglePage
                        current={currentPage}
                        onChange={index => onChangePage(index)}
                        defaultCurrent={1}
                        total={dataSource.length}
                        showSizeChanger={false}
                    />
                    <Select
                        value={pageSize}
                        defaultValue={pageSize}
                        options={configs.PageSizes}
                        onChange={onChangePageSize}
                        style={{ marginLeft: '10px' }}
                        pagination={{ position: ['none', 'none'], pageSize: pageSize }}
                    />
                </Row>
            </div>

            <ModalEditQuyChuan ref={modalRef} />

            {contextHolder}
        </div>
    );
};

export default QuyChuanBangXe;
