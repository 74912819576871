import { Button, Col, DatePicker, Flex, Input, Modal, Row, Select, notification } from 'antd';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import AxiosService from '../../../../common/AxiosService';
import { apis } from '../../../../constants';
import { checkEmptyData, formatThickWidth, formatWeightText, getPullingSampleResult } from '../../../../helpers';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import style from "./chiTietTonCuon.module.less";
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { generateNewTypeOfSteel } from '../../../../utils/steelMasterType';
import { appLoading, setAppProperties } from '../../../../ducks/slices/appSlice';
import _ from 'lodash';
import { CONSIGNMENT, MATERIAL_TYPE } from '../../../../constants/utils';

const ChiTietTonCuon = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { reloadData = () => { } } = props;
  const [modal, contextHolder] = Modal.useModal();
  const [isModalOpen, set_isModalOpen] = useState(false);
  const [data, set_data] = useState([])
  const [newSampleObject , set_newSampleObject] = useState(null)
  const [providerName, set_providerName] = useState('')
  const [coilId, set_coilId] = useState('')
  const [steelGrade, set_steelGrade] = useState('')
  const [importDate, set_importDate] = useState('')
  const [steelType, set_steelType] = useState('')
  const [typeByCustomer, set_typeByCustomer] = useState('')
  const [temWeight, set_temWeight] = useState('')
  const [consignment, set_consignment] = useState('');
  const [netWeight, set_netWeight] = useState('');

  const [materialType, set_materialType] = useState(null);// loại hàng 1/2
  const [rackId, set_rackId] = useState('');// giá băng xẻ
  const [providerId, set_providerId] = useState('');//mã nhà cung cấp
  
  const [sizeThick, set_sizeThick] = useState('')
  const [sizeLong, set_sizeLong] = useState('')
  const [note, set_note] = useState('')
  const [producerCode, set_producerCode] = useState('')
  const [dataPullSample, set_dataPullSample] = useState([])
  const app = useSelector(state => state.app)
  const auth = useSelector(state =>state.auth)

  const [checkWrapError, set_checkWrapError] = useState('')
  const [checkRusty, set_checkRusty] = useState('')
  const [checkDeformedEdge, set_checkDeformedEdge] = useState('')
  const [checkTornPerforation, set_checkTornPerforation] = useState('')

  useImperativeHandle(ref, () => ({
    openModal(data) {
      set_isModalOpen(true)
      initModalData(data)
      // loadRequestDetail(requestId)
    },

  }));

  const initModalData = (data) => {
    set_data(data)
    set_providerName(data.providerName)
    set_coilId(data.nsmvCoilId)
    set_steelGrade(data.steelGrade)
    set_importDate(dayjs(data.importDate, "DD/MM/YYYY"))
    let typeCustomer = app.customerMaster.find(item => item.label === data.typeByCustomer);
    if (!!typeCustomer) {
      set_typeByCustomer(typeCustomer.value)
    }

    let type = app.steelTypeMaster.find(item => item.label === data.steelType);
    if (!!type) {
      set_steelType(type.value)
    }
    set_temWeight(data.temWeight)
    set_sizeThick(data.sizeWidth)
    set_sizeLong(data.sizeDepth)
    set_netWeight(data.netWeight)
    set_note(data.note)
    set_producerCode(data.producerCode)
    getDataPullingSample(data)
    set_checkWrapError(checkEmptyData(data.checkWrapError, ""))
    set_checkRusty(checkEmptyData(data.checkRusty, ""))
    set_checkDeformedEdge(checkEmptyData(data.checkDeformedEdge, ""))
    set_checkTornPerforation(checkEmptyData(data.checkTornPerforation, ""))
    set_typeByCustomer(checkEmptyData(data.typeByCustomer, ""))
    set_consignment(data.consignment)
    set_materialType(data.materialType);
    set_rackId(data.rackId || "");
    set_providerId(data.providerId || "");
  }

  const handleOk = () => {
    set_isModalOpen(false);
  };
  const handleCancel = () => {
    set_isModalOpen(false);
    set_coilId('')
    set_providerName('')
    set_coilId('')
    set_steelGrade('')
    set_importDate(dayjs(''))
    set_steelType('')
    set_temWeight('')
    set_netWeight('')
    set_sizeThick('')
    set_sizeLong('')
    set_note('')
    set_producerCode('')
    set_data({})
    set_dataPullSample([])
    set_typeByCustomer('')
    set_newSampleObject(null)
    set_consignment('')
    set_rackId('');
    set_providerId('');
    set_materialType('');
  };

  const getDataPullingSample = async(roll) => {
    let dataRequest = {
      id: roll.id
    }
    try {
      const {data} = await AxiosService.post(apis.Url_Api_Post_LayDanhSachMauKeoThuTheoId, dataRequest);
      if(data.status == 200) {
        const {pullingRoll, pullingTape} = data.data;
        var lsSample = pullingRoll?.map(item => ({...item, wareHouseName: "C"})) || [];
        if(app.wareHouseName == "X" || !!pullingTape) {
          lsSample = lsSample.concat(pullingTape.map(item => ({...item, wareHouseName: "X"})))
        }
        // console.log("Hallo",lsSample)
        set_dataPullSample(lsSample)
      }
      else{
        set_dataPullSample([]);
      }
    }
    catch(e) {
      console.log("Error",e)
      notification.error({message: e.message})
    }
  }

  const requestEditCoilData = async () => {
    await createNewSteelTypes();
    if (!!newSampleObject) {
      if (!!newSampleObject.id) {
        onSubmitSaveEditPullSample(newSampleObject)
      } else {
        onPressSavePullSample()
      }
    }
    let dataRequest = {
      ...data,
      "rollProducerId": data.id,
      "coilId": coilId,
      "steelGrade": steelGrade,
      "importDate": dayjs(importDate).format('DD/MM/YYYY'),
      "steelType": !!steelType.includes('create-new-') ? steelType.split('create-new-')[1] : steelType,
      "temWeight": Number(temWeight),
      "size": sizeLong + "×" + sizeThick,
      "providerName": providerName,
      "netWeight" : netWeight,
      "producerCode": producerCode,
      "note": note,
      checkWrapError,
      checkRusty,
      checkDeformedEdge,
      checkTornPerforation,
      typeByCustomer,
      consignment,
      materialType,
      rackId: checkEmptyData(rackId),
      providerId: checkEmptyData(providerId)
    }
    AxiosService.post(apis.Url_Api_Post_EditThongTinTonCuon, dataRequest)
      .then(res => {
        let { data } = res;
        if (!!data && data.message === "OK") {
          notification.success({ message: "Sửa thông tin thành công" })
          reloadData();
          handleCancel();
        } else {
          notification.error({ message: data.message })
        }
      })
      .catch(e => notification.error({ message: e.message }))
  }

  const createNewSteelTypes = async () => {
    let newSteelTypes = app.steelTypeMaster.filter(e => !!e.value.includes('create-new-'));
    newSteelTypes = newSteelTypes.map(e => {
      return {
        value: e.value.split("create-new-")[1],
        label: e.label
      };
    });
    await Promise.all(
      newSteelTypes.map(async e => {
        const requestData = {
          steelTypeName: e.label,
          steelTypeAcronym: e.value
        }
        try {
          await AxiosService.post(apis.Url_Api_Add_MasterData_SteelType, requestData)
        } catch (err) {
          console.log('createNewSteelTypes', err);
          notification.error({ error: err.message })
        }
      })
    )
  }

  const onSearchSteelMaster = _.debounce(function (searchTerm) {
    const isExist = app.steelTypeMaster.find(e => e.label === searchTerm.trim());

    if (!isExist) {
      const oldSteelTypeMaster = app.steelTypeMaster.filter(e => !e.value.includes('create-new-'));

      let newSteelTypeMaster = [
        ...oldSteelTypeMaster,
      ]

      if (!!searchTerm && searchTerm !== "") {
        const newElement = generateNewTypeOfSteel({
          searchTerm,
          originalSteelMasterTypes: oldSteelTypeMaster,
          addedSteelTypeMaster: []
        });
        newSteelTypeMaster = !!newElement ? [...newSteelTypeMaster, { ...newElement }] : [...newSteelTypeMaster];
      }

      dispatch(setAppProperties({
        steelTypeMaster: [...newSteelTypeMaster]
      }))
    }
  }, 100);

  const onSteelTypeInputChange = (inputData) => {
    if (!inputData.includes('create-new-')) {
      dispatch(setAppProperties({
        steelTypeMaster: [
          ...app.steelTypeMaster.filter(e => {
            // lấy những loại cũ và những loại mới ở row khác
            return !e.value.includes('create-new-');
          })
        ]
      }))
    }
    set_steelType(inputData);
  }

  const onPressSavePullSample = () => {
    // if (!(!!newSampleObject.yieldStrength
    //   && !!newSampleObject.methodUsed
    //   && !!newSampleObject.widthPullSize
    //   && !!newSampleObject.thicknessSize
    //   && !!newSampleObject.typeSize
    //   && !!newSampleObject.elongation
    //   && !!newSampleObject.firstTensileStrength
    //   && !!newSampleObject.secondTensileStrength
    //   && !!newSampleObject.thirdTensileStrength
    //   && !!newSampleObject.result)) {
    //   notification.error({ message: "Cần điền đầy đủ thông tin mẫu kéo" })
    //   return;
    // }
    
    dispatch(appLoading(true))
    let dataRequest = {
      ...newSampleObject,
      idRollNsmv: data.id,
      labDate: newSampleObject.labDate.format('DD/MM/YYYY'),
      status: (!!newSampleObject.yieldStrength
        && !!newSampleObject.elongation
        && !!newSampleObject.firstTensileStrength
        && !!newSampleObject.secondTensileStrength
        && !!newSampleObject.thirdTensileStrength
        && !!newSampleObject.result) ? 1 : 0
    }
    let roll = data;
    AxiosService.post(apis.Url_Api_Post_TaoMauKeoThu, dataRequest)
      .then(res => {
        let { data } = res;
        if (!!data && data.message === "OK") {
          dispatch(appLoading(false))
          getDataPullingSample(roll)
          notification.success({ message: "Tạo mẫu kéo thành công" })
          set_isModalOpen(false);
          set_newSampleObject(pullSampleObject)
        } else {
          dispatch(appLoading(false))
          notification.error({ message: data.message })
        }
      })
      .catch(e => {
        dispatch(appLoading(false))
        notification.error({ message: e.message })
      })
  }

  const onSubmitSaveEditPullSample = (dataSample) => {
    // if (!(!!newSampleObject.yieldStrength
    //   && !!newSampleObject.methodUsed
    //   && !!newSampleObject.widthPullSize
    //   && !!newSampleObject.thicknessSize
    //   && !!newSampleObject.typeSize
    //   && !!newSampleObject.elongation
    //   && !!newSampleObject.firstTensileStrength
    //   && !!newSampleObject.secondTensileStrength
    //   && !!newSampleObject.thirdTensileStrength
    //   && !!newSampleObject.result)) {
    //   notification.error({ message: "Cần điền đầy đủ thông tin mẫu kéo" })
    //   return;
    // }
    dispatch(appLoading(true))
    let requestBody = {
      ...dataSample, labDate: dataSample.labDate.format('DD/MM/YYYY'), status: (!!newSampleObject.yieldStrength
        && !!newSampleObject.elongation
        && !!newSampleObject.firstTensileStrength
        && !!newSampleObject.secondTensileStrength
        && !!newSampleObject.thirdTensileStrength
        && !!newSampleObject.result) ? 1 : 0
    }
    let roll = data;
    AxiosService.post(apis.Url_Api_Post_LuuMauKeoThu, requestBody)
      .then(res => {
        let { data } = res;
        if (!!data && data.message === "OK") {
          dispatch(appLoading(false))
          getDataPullingSample(roll)
          notification.success({ message: "Lưu mẫu kéo thành công" })
          set_isModalOpen(false);
          set_newSampleObject(pullSampleObject)
        } else {
          dispatch(appLoading(false))
          notification.error({ message: data.message })
        }
      })
      .catch(e => {
        dispatch(appLoading(false))
        notification.error({ message: e.message })
      })
  }

  const onClickEditSample = (item) => {
    let editdata = { ...item, labDate: dayjs(item.labDate, 'DD/MM/YYYY'), note: item.pullNote, result: !!item.result || item.result === 0 ? item.result.toString() : "" }
    set_newSampleObject(editdata)
  }
  const onRequestDeleteSample = (id) => {
    dispatch(appLoading(true))
    let requestData = {
      listRollPullId: [id]
    }
    let roll = data;
    AxiosService.post(apis.Url_Api_Post_XoaMauKeoThu, requestData)
      .then(res => {
        let { data } = res;
        if (!!data && data.message === "OK") {
          dispatch(appLoading(false))
          getDataPullingSample(roll)
          notification.success({ message: "Xóa mẫu kéo thành công" })
          set_newSampleObject(pullSampleObject)
        } else {
          dispatch(appLoading(false))
          notification.error({ message: data.message })
        }
      })
      .catch(e => {
        dispatch(appLoading(false))
        notification.error({ message: e.message })
      })
  }

  return <Modal title="CHI TIẾT TÔN CUỘN" closeIcon={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className={style['modal-container']} width={'75vw'}

    footer={[
    ]}
  >
    <div className={style['main']}>
      <Row gutter={[10, 20]} align={'middle'}>
        <Col span={2} className={style['filter-title']}>Ngày nhập</Col>
        <Col span={4}><DatePicker format={"DD/MM/YYYY"} className={style['filter-input']} value={importDate} onChange={(date, dateString) => { set_importDate(date) }} /></Col>
        <Col span={2} className={style['filter-title']}>{auth.wareHouseName == 'X' ? 'Số dải' : 'Số cuộn'}</Col>
        <Col span={4}><Input className={style['filter-input']} style={{ color: '#000' }} required value={coilId} onChange={({ target }) => set_coilId(target.value)} /></Col>
        {
          !!data.nsmvRollCoilId && <>
            <Col span={2} className={style['filter-title']}>Cuộn ban đầu</Col>
            <Col span={4}><Input className={style['filter-input']} style={{ color: '#000' }} required value={data.nsmvRollCoilId} disabled/></Col>
          </>
        }
        <Col span={2} className={style['filter-title']}>Mác thép</Col>
        <Col span={4}><Input className={style['filter-input']} required value={steelGrade} onChange={({ target }) => set_steelGrade(target.value)} /></Col>
        <Col span={2} className={style['filter-title']}>Chủng loại</Col>
        <Col span={4}><Select
          className={style['filter-select']}
          onChange={onSteelTypeInputChange}
          // defaultValue={''}
          value={steelType}
          showSearch
          optionFilterProp="label"
          dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
          options={app.steelTypeMaster.filter(item => !!item.value)}
          onSearch={onSearchSteelMaster}
        /></Col>
        <Col span={2} className={style['filter-title']}>Nhà cung cấp</Col>
        <Col span={4}><Input className={style['filter-input']} value={providerName} onChange={({ target }) => set_providerName(target.value)} /></Col>
        {
          auth.wareHouseName == 'C' && <>
           <Col span={2} className={style['filter-title']}>Mã cung cấp</Col>
           <Col span={4}><Input className={style['filter-input']} value={providerId} onChange={({ target }) => set_providerId(target.value)} /></Col>
          </>
        }
        <Col span={2} className={style['filter-title']}>Dày × rộng</Col>
        <Col span={4}>
          <Flex align="center">
            <Input className={style['filter-input']} value={sizeLong} onChange={({ target }) => set_sizeLong(target.value.replace(/[^0-9.]/g, ""))} />
            <div style={{ marginLeft: '10px', marginRight: '10px' }}>×</div>
            <Input className={style['filter-input']} value={sizeThick} onChange={({ target }) => set_sizeThick(target.value.replace(/[^0-9.]/g, ""))} /></Flex>
        </Col>
        <Col span={2} className={style['filter-title']}>Trọng lượng</Col>
        <Col span={4}><Input className={style['filter-input']} value={temWeight} onChange={({ target }) => set_temWeight(target.value.replace(/[^0-9.]/g, ""))} /></Col>
        <Col span={2} className={style['filter-title']}>Trọng lượng còn lại</Col>
        <Col span={4}><Input className={style['filter-input']} value={netWeight} onChange={({ target }) => set_netWeight(target.value.replace(/[^0-9.]/g, ""))} /></Col>
        <Col span={2} className={style['filter-title']}>Hàng tồn</Col>
        <Col span={4}>
        <Select
            className={style['filter-select']}
            onChange = {(value)  => set_consignment(value)}
            value={consignment}
            optionFilterProp="label"
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={CONSIGNMENT}
          />
        </Col>
        <Col span={2} className={style['filter-title']}>Nhà sản xuất</Col>
        <Col span={4}><Input className={style['filter-input']} value={producerCode} onChange={({ target }) => set_producerCode(target.value)} /></Col>

        <Col span={2} className={style['filter-title']}>Loại hàng</Col>
        <Col span={4}><Select
            className={style['filter-select']}
            onChange={set_materialType}
            value={materialType}
            showSearch
            optionFilterProp="label"
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={MATERIAL_TYPE}
        /></Col>
        {
          auth.wareHouseName == 'X' && <>
            <Col span={2} className={style['filter-title']}>Giá</Col>
            <Col span={4}><Input className={style['filter-input']} value={rackId} onChange={({ target }) => set_rackId(target.value)} /></Col>
          </>
        }

        <Col span={2} className={style['filter-title']}>Phân loại khách hàng</Col>
        <Col span={4}><Select
          className={style['filter-select']}
          onChange={set_typeByCustomer}
          defaultValue={''}
          value={typeByCustomer}
          showSearch
          optionFilterProp="label"
          dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
          options={app.customerMaster.filter(item => !!item.value)}
        /></Col>

        <Col span={2} className={style['filter-title']}>Quấn lỗi</Col>
        <Col span={4}><Select
          className={style['filter-select']}
          onChange={set_checkWrapError}
          defaultValue={''}
          value={checkWrapError}
          dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
          options={masterSelectOutLook}
        /></Col>

        <Col span={2} className={style['filter-title']}>Han gỉ</Col>
        <Col span={4}><Select
          className={style['filter-select']}
          onChange={set_checkRusty}
          defaultValue={''}
          value={checkRusty}
          dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
          options={masterSelectOutLook}
        /></Col>

        <Col span={2} className={style['filter-title']}>Mép biến dạng</Col>
        <Col span={4}><Select
          className={style['filter-select']}
          onChange={set_checkDeformedEdge}
          defaultValue={''}
          value={checkDeformedEdge}
          dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
          options={masterSelectOutLook}
        /></Col>

        <Col span={2} className={style['filter-title']}>Rách thủng</Col>
        <Col span={4}><Select
          className={style['filter-select']}
          onChange={set_checkTornPerforation}
          defaultValue={''}
          value={checkTornPerforation}
          dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
          options={masterSelectOutLook}
        /></Col>
       
       
      </Row>
      <Row gutter={[10, 20]} align={'middle'} style={{marginTop: '25px'}}>
        <Col span={2} className={style['filter-title']}>Ghi chú</Col>
        <Col span={10}><Input.TextArea className={style['filter-input']} value={note} onChange={({ target }) => set_note(target.value)} style={{height: '80px'}} /></Col>
      </Row>
      <div>
        <Row align={'middle'} style={{ marginTop: '20px', marginBottom: '20px' }} gutter={[20]}>
          <Col>
            <div className={style['sample-title']}>MẪU KÉO THỬ </div>
          </Col>
          {newSampleObject == null &&
            <Col>
              <Button type='primary' onClick={() => set_newSampleObject(pullSampleObject)}>Thêm</Button>
            </Col>}

        </Row>
        <Row gutter={[10, 20]} align={'middle'}>
          {!!newSampleObject && <Col key={'new_sample'} span={24}>
            <Row className={style['sample-block']} gutter={[10, 10]} align={'bottom'}>
              <Col span={6}> Ngày thí nghiệm: <DatePicker format={"DD/MM/YYYY"} value={newSampleObject.labDate} className={style['filter-input']} onChange={(date, dateString) => set_newSampleObject(curr => ({ ...curr, labDate: date }))} /> </Col>
              <Col span={6}> Loại/Kích thước: <Input className={style['filter-input']} value={newSampleObject.typeSize} onChange={({ target }) => set_newSampleObject(curr => ({ ...curr, typeSize: target.value }))} /> </Col>
              <Col span={6}> Độ dày, rộng: <Row gutter={10} justify={'space-between'}>
                <Col span={11}><Input className={style['filter-input']} value={newSampleObject.thicknessSize} onChange={({ target }) => set_newSampleObject(curr => ({ ...curr, thicknessSize: target.value.replace(/[^0-9.]/g, "") }))} /></Col>
                ×
                <Col span={11}><Input className={style['filter-input']} value={newSampleObject.widthPullSize} onChange={({ target }) => set_newSampleObject(curr => ({ ...curr, widthPullSize: target.value.replace(/[^0-9.]/g, "") }))} /></Col>
              </Row> </Col>
              <Col span={6}> Giới hạn chảy: <Input className={style['filter-input']} value={newSampleObject.yieldStrength} onChange={({ target }) => set_newSampleObject(curr => ({ ...curr, yieldStrength: target.value.replace(/[^0-9.]/g, "") }))} /> </Col>
              <Col span={6}> Giới hạn bền: <Input className={style['filter-input']} value={newSampleObject.thirdTensileStrength} onChange={({ target }) => set_newSampleObject(curr => ({ ...curr, thirdTensileStrength: target.value.replace(/[^0-9.]/g, "") }))} /> </Col>
              {/* <Col span={12}> Giới hạn bền: <Row gutter={10} justify={'space-between'}>
                <Col span={7}><Input className={style['filter-input']} value={newSampleObject.firstTensileStrength} onChange={({ target }) => set_newSampleObject(curr => ({ ...curr, firstTensileStrength: target.value.replace(/[^0-9.]/g, "") }))} /></Col>
                ×
                <Col span={7}><Input className={style['filter-input']} value={newSampleObject.secondTensileStrength} onChange={({ target }) => set_newSampleObject(curr => ({ ...curr, secondTensileStrength: target.value.replace(/[^0-9.]/g, "") }))} /></Col>
                ×
                <Col span={7}><Input className={style['filter-input']} value={newSampleObject.thirdTensileStrength} onChange={({ target }) => set_newSampleObject(curr => ({ ...curr, thirdTensileStrength: target.value.replace(/[^0-9.]/g, "") }))} /></Col>
              </Row> </Col> */}
              <Col span={6}> Độ giãn dài: <Input className={style['filter-input']} value={newSampleObject.elongation} onChange={({ target }) => set_newSampleObject(curr => ({ ...curr, elongation: target.value.replace(/[^0-9.]/g, "") }))} /> </Col>

              <Col span={6}> Phương pháp:
                <Select
                  className={style['filter-select']}
                  onChange={(methodUsed) => set_newSampleObject(curr => ({ ...curr, methodUsed }))}
                  defaultValue={''}
                  value={newSampleObject.methodUsed}
                  dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                  options={masterSelectMethodUsed}
                />
              </Col>

              <Col span={6}> Kết quả:<Select
                className={style['filter-select']}
                onChange={(result) => set_newSampleObject(curr => ({ ...curr, result }))}
                defaultValue={''}
                value={newSampleObject.result}
                dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                options={masterSelectPullResult}
              /></Col>
              <Col flex={"auto"}> Ghi chú: <Input className={style['filter-input']} value={newSampleObject.note} onChange={({ target }) => set_newSampleObject(curr => ({ ...curr, note: target.value }))} /> </Col>

              <Button type='primary' onClick={() => !!newSampleObject.id ? onSubmitSaveEditPullSample(newSampleObject) : onPressSavePullSample()}>Lưu mẫu kéo</Button>
              {!!newSampleObject.id ? <Button danger
                style={{ marginLeft: '7px' }}
                onClick={() => {
                  set_newSampleObject(null)
                }}>Hủy</Button> :
                <Button danger
                  style={{ marginLeft: '7px' }}
                  onClick={() => {
                    modal.confirm({
                      title: 'Xác nhận xóa',
                      icon: <ExclamationCircleOutlined />,
                      content: 'Bạn chắc chắn muốn xóa mẫu thử này?',
                      okText: 'Xóa',
                      okType: 'danger',
                      onOk: () => set_newSampleObject(null),
                      cancelText: 'Đóng',
                    });
                  }}>Xoá</Button>}

            </Row>
          </Col>}
          {dataPullSample.map((item, index) => <Col key={index} span={12}>
            <Row className={style['sample-block']} gutter={[10, 10]}>
              <Col span={24}>
                <Row gutter={10}>
                  <Col flex={'auto'}> Ngày thí nghiệm: {item.labDate} </Col>
                  {/* {item.rollPullStatus !== "1" &&  */}
                  {
                    auth.wareHouseName == item.wareHouseName && (
                      <>
                       <Col>
                        {newSampleObject?.id === item?.id ? (
                          <EditOutlined style={{ color: '#6e7ee7', pointerEvents: 'none', opacity: 0.5 }} />
                        ) : (
                          <EditOutlined style={{ color: '#6e7ee7' }} onClick={() => onClickEditSample(item)} />
                        )}
                      </Col>
                      <Col><DeleteOutlined style={{ color: '#f27e7c' }} onClick={() => {
                        modal.confirm({
                          title: 'Xác nhận xóa',
                          icon: <ExclamationCircleOutlined />,
                          content: 'Bạn chắc chắn muốn xóa mẫu thử này?',
                          okText: 'Xóa',
                          okType: 'danger',
                          onOk: () => onRequestDeleteSample(item.id),
                          cancelText: 'Đóng',
                        });
                      }} /></Col>
                      </>
                    )
                  }
                </Row>
              </Col>

              <Col span={11}> Loại/Kích thước: {item.typeSize} </Col>
              <Col span={13}> Độ dày, rộng: {formatThickWidth(item.thicknessSize, item.widthPullSize)}</Col>
              <Col span={11}> Phương pháp: {item.methodUsed} </Col>
              <Col span={13}> Giới hạn chảy: {item.yieldStrength ? `${formatWeightText(item.yieldStrength)} N/mm²` : ''}</Col>
              <Col span={11}> Độ giãn dài: {item.elongation ? `${formatWeightText(item.elongation)}%` : ''} </Col>
              <Col span={13}> Giới hạn bền: {item.thirdTensileStrength ? `${formatWeightText(item.thirdTensileStrength)} N/mm²` : ''} </Col>
              <Col span={11}> Kết quả: {getPullingSampleResult(item.result)} </Col>
              <Col span={13}/>
              <Col span={24}> Ghi chú: {item.pullNote} </Col>
            </Row>
          </Col>)
          }
        </Row></div>
      <Row align={'middle'} style={{ marginTop: '20px' }} gutter={[20]} justify={'end'}>
        <Col>
          <Button key="back" onClick={handleCancel}>Đóng</Button>
        </Col>
        <Col>
          <Button type='primary'
            onClick={requestEditCoilData}>Lưu</Button>
        </Col>
        {contextHolder}
      </Row>
    </div>
  </Modal>
});

export default ChiTietTonCuon;

const masterSelectOutLook =[
  {value: '', label: ''},
  {value: '1', label: 'Có'},
  {value: '0', label: 'Không'},
]

const masterSelectPullResult =[
  {value: '', label: '', className: style['hidden']},
  {value: '1', label: 'Đạt'},
  {value: '0', label: 'Không đạt'},
]

const masterSelectMethodUsed = [
  { value: '', label: '', className: style['hidden'] },
  { value: '11A号', label: '11A号' },
  { value: '12A号', label: '12A号' },
  { value: '12B号', label: '12B号' },
  { value: '12号', label: '12号' },
  { value: '5号', label: '5号' },
]


const pullSampleObject = {
  methodUsed : "",
  thicknessSize: "",
  widthPullSize: "",
  yieldStrength: "",
  firstTensileStrength: "",
  secondTensileStrength: "",
  thirdTensileStrength: "",
  elongation: "",
  typeSize: "",
  ts:"",
  result: "",
  note: "",
  labDate : dayjs(),
}