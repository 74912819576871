import { Col, Row } from "antd";
import style from './systemmap.module.less';
import cn from "classnames";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { paths } from "../../../../constants";
import { useSelector } from "react-redux";

const SystemMap = () => {
    const history = useHistory();
    const {wareHouseName} = useSelector(state => state.auth)

    const clicknavigation = (routeName) => {
        history.push(routeName)
    }
    return <div>
        <h1>Sơ đồ hệ thống NSMV</h1>
        <Row align={'middle'}>
            <Col span={24} className={style['col-block']}><div className={style['navigation-block']}>Hệ thống NSMV</div></Col>

            <Col span={12} className={style['side-line-grid-block']}/>
            <Col span={12} />
            <Col span={2} style={{height: 30}}/>
            <Col span={4} className={style['line-grid-block']} style={{borderRadius: '10px 0 0 0'}}/>
            <Col span={4} className={cn([style['line-grid-block'], style['inner']])}/>
            <Col span={4} className={cn([style['line-grid-block'], style['inner']])}/>
            <Col span={4} className={cn([style['line-grid-block'], style['inner']])}/>
            <Col span={4} className={cn([style['line-grid-block'], style['inner']])} style={{borderRadius: '0 10px 0 0'}}/>
            <Col span={2} style={{height: 30}} />

            <Col span={4} className={style['col-block']}><div className={style['navigation-block']}>Tổng quan</div></Col>
            <Col span={4} className={style['col-block']}><div onClick={() => clicknavigation(paths.main_danh_sach_ton_kho)} className={style['navigation-block']}>Tồn kho</div></Col>
            <Col span={4} className={style['col-block']}><div onClick={() => clicknavigation(paths.main_them_moi_nhap_kho)} className={style['navigation-block']}>Nhập kho</div></Col>
            <Col span={4} className={style['col-block']}><div onClick={() => clicknavigation(paths.main_them_moi_xuat_kho)} className={style['navigation-block']}>Xuất kho</div></Col>
            <Col span={4} className={style['col-block']}><div onClick={() => clicknavigation(paths.main_them_moi_chi_thi_kiem_ke)} className={style['navigation-block']}>Kiểm kê</div></Col>
            <Col span={4} className={style['col-block']}><div onClick={() => clicknavigation(paths.main_bao_cao_thong_ke)} className={style['navigation-block']}>Báo cáo</div></Col>

            <Col span={10} className={style['side-line-grid-block']}/>
            <Col span={4} className={style['side-line-grid-block']}/>
            <Col span={4} className={style['side-line-grid-block']}/>
            <Col span={4} className={style['side-line-grid-block']}/>
            <Col span={2} />

            <Col span={8} className={style['col-block']}/>
            <Col span={4} className={style['col-block']}><div  onClick={() => clicknavigation(paths.main_them_moi_nhap_kho)}  className={style['navigation-block']}>Tạo chỉ thị nhập</div></Col>
            <Col span={4} className={style['col-block']}><div  onClick={() => clicknavigation(paths.main_them_moi_xuat_kho)}  className={style['navigation-block']}>Tạo chỉ thị xuất</div></Col>
            <Col span={4} className={style['col-block']}><div  onClick={() => clicknavigation(paths.main_them_moi_chi_thi_kiem_ke)}  className={style['navigation-block']}>Tạo chỉ thị kiểm kê</div></Col>
            <Col span={4} className={style['col-block']}><div  onClick={() => clicknavigation(paths.main_bao_cao_thong_ke)}  className={style['navigation-block']}>Thống kê</div></Col>

            <Col span={10} className={style['side-line-grid-block']}/>
            <Col span={4} className={style['side-line-grid-block']}/>
            <Col span={4} className={style['side-line-grid-block']}/>
            <Col span={4} className={style['side-line-grid-block']}/>
            <Col span={2} />

            <Col span={8} className={style['col-block']}/>
            <Col span={4} className={style['col-block']}><div onClick={() => clicknavigation(paths.main_danh_sach_nhap_kho)}  className={style['navigation-block']}>Dự kiến nhập</div></Col>
            <Col span={4} className={style['col-block']}><div onClick={() => clicknavigation(paths.main_danh_sach_xuat_kho)}  className={style['navigation-block']}>DS xuất kho</div></Col>
            <Col span={4} className={style['col-block']}><div onClick={() => clicknavigation(paths.main_danh_sach_chi_thi_kiem_ke)}  className={style['navigation-block']}>Kết quả kiểm kê</div></Col>
            <Col span={4} className={style['col-block']}><div onClick={() => clicknavigation(paths.main_bao_cao_xuat_file)}  className={style['navigation-block']}>Xuất báo cáo</div></Col>

            {
                wareHouseName == 'X' && <>
                  <Col span={10} className={style['side-line-grid-block']}/>
                  <Col span={14} />
                  <Col span={8} className={style['col-block']}/>
                  <Col span={4} className={style['col-block']}><div onClick={() => clicknavigation(paths.main_ds_yeu_cau_xe_bang)}  className={style['navigation-block']}>Yêu cầu xẻ băng</div></Col>
                </>
            }
        </Row>
       </div>
    ;
}

export default SystemMap