import React, { useState, useImperativeHandle, memo, forwardRef } from 'react';
import { Button, Col, Input, Modal, Row, notification } from 'antd';
import AxiosService from '../../../../../common/AxiosService';
import { useDispatch } from 'react-redux';
import style from './ModalEditQuyChuan.module.less';
import cn from 'classnames';
import { apis } from '../../../../../constants';
import { refetchMasterStandard } from '../../../../../ducks/slices/appSlice';

const ModalEditQuyChuan = forwardRef((_, ref) => {
    const dispatch = useDispatch();
    const [isModalOpen, set_isModalOpen] = useState(false);

    const [headId, set_headId] = useState('');
    const [bodyId, set_bodyId] = useState('');
    const [thickProduct, set_thickProduct] = useState('');
    const [typeProduct, set_typeProduct] = useState('');
    const [widthTape, set_widthTape] = useState('');
    const [submited, set_submited] = useState(false);
    //phân biệt thép thường vs thép đặc biệt
    const [productFlg, set_productFlg] = useState(null);

    const [numberBundle, set_numberBundle] = useState('');
    const [loading, set_loading] = useState(false);

    const handleOk = () => {};
    const handleClose = () => {
        set_isModalOpen(false);
    };

    const onSumbitNewValue = async () => {
        set_submited(true);
        if (!!typeProduct && !!widthTape && !!thickProduct) {
            //do something
            const dataSend = {
                headId,
                bodyId,
                thicknessProduct: widthTape,
                typeProduct,
                specificSize: thickProduct,
                numberBundles: 160,
                productFlg,
            };
            //console.log(dataSend);
            set_loading(true);
            try {
                const res = await AxiosService.post(apis.Url_Api_Update_Split_Tape_Standard, dataSend);
                if (res.data.status == 200) {
                    dispatch(refetchMasterStandard());
                    notification.success({ message: 'Sửa dữ liệu thành công' });
                    handleClose();
                } else {
                    notification.error({ message: 'Sửa dữ liệu thất bại' });
                    console.log(res.data);
                }
            } catch (e) {
                notification.error({ message: 'Sửa dữ liệu thất bại' });
                console.log(e);
            } finally {
                set_loading(false);
            }
        }
    };

    useImperativeHandle(ref, () => ({
        openModal(record) {
            set_isModalOpen(true);
            const { headId, bodyId, typeProduct, thicknessProduct, widthTape, productFlg } = record;
            set_headId(headId);
            set_bodyId(bodyId);
            set_typeProduct(typeProduct);
            set_thickProduct(thicknessProduct);
            set_widthTape(widthTape);
            set_productFlg(productFlg);
        },
    }));

    return (
        <Modal
            title={'SỬA QUY CHUẨN BĂNG XẺ'}
            closeIcon={false}
            open={isModalOpen}
            onOk={handleOk}
            confirmLoading={loading}
            onCancel={handleClose}
            className={style['modal-container']}
            width={'80vw'}
            footer={[
                <Button key="back" onClick={handleClose}>
                    Đóng
                </Button>,

                <Button type="primary" key="submit" onClick={onSumbitNewValue}>
                    Lưu thay đổi
                </Button>,
            ]}
        >
            <Row className={style['row-input']} style={{ marginBottom: '20px' }}>
                <Col span={2}>{`Loại sp (mm)`}</Col>
                <Col span={4}>
                    <Input
                        className={cn(style['filter-input'], submited && typeProduct == '' && style['warning-input'])}
                        value={typeProduct}
                        onChange={({ target }) => set_typeProduct(target.value)}
                    />
                </Col>
                <Col span={2}>{`Dày sp (mm)`}</Col>
                <Col span={4}>
                    <Input
                        className={cn(style['filter-input'], submited && thickProduct == '' && style['warning-input'])}
                        value={thickProduct}
                        onChange={({ target }) => set_thickProduct(target.value.replace(/[^0-9.]/g, ''))}
                    />
                </Col>
                <Col span={3}>{`Rộng băng xẻ (mm)`}</Col>
                <Col span={4}>
                    <Input
                        className={cn(style['filter-input'], submited && widthTape == '' && style['warning-input'])}
                        value={widthTape}
                        onChange={({ target }) => set_widthTape(target.value.replace(/[^0-9.]/g, ''))}
                    />
                </Col>
                {/* <Col span={2}>{`Số lượng cây/bó`}</Col>
                <Col span={4}>
                    <Input
                        className={cn(style['filter-input'], submited && numberBundle == '' && style['warning-input'])}
                        value={numberBundle}
                        onChange={({ target }) => set_numberBundle(target.value.replace(/[^0-9.]/g, ''))}
                    />
                </Col> */}
            </Row>
        </Modal>
    );
});

export default memo(ModalEditQuyChuan);
