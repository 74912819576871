import React, { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useState, useCallback } from 'react';
import style from './EditYeuCau.module.less';
import { Col, DatePicker, Input, Row, Select, Space, Table, Button, Modal, notification } from 'antd';
import { PlusOutlined, MinusOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { sumBy, mean, uniqBy } from 'lodash';
import TextArea from 'antd/es/input/TextArea';
import { buildEditBlock } from '../../ThemMoiNhapKho/utils';
import { produce } from 'immer';
import { ORDER_OPTION } from '../../ThemMoiNhapKho/BlockYeuCauV2/BlockYeuCauV2';

const MAX_NEGATIVE_WIDTH_TAPE = -5;

const initialStandard = {
    // thicknessMin: '',
    id: 'standard_0',
    thicknessProduct: '',
    sizeWidthTape: '',
    typeProduct: '',
    stripNumber: '',
    totalWidth: '',
    orderCode: '',
    //weightCal: '',
    note: '',
};

const initialRoll = {
    id: 'roll_0',
    rollId: '',
    importDate: '',
    weight: '',
    steelType: '',
    steelGrade: '',
    ts: '',
    thickWidth: '',
};

const EditYeuCauModal = forwardRef(({ saveEdit }, ref) => {
    const [visible, set_visible] = useState(false);
    const rollMaster = useSelector(state => state.app.listRollManufacture);
    //list thành phẩm (Lấy master từ server)

    const standardMaster = useSelector(state => state.app.listMasterStandard);
    const thickMaster = useSelector(state => state.app.listThickStandard);
    const standardMasterSpecial = useSelector(state => state.app.listMasterStandardSpecial);
    const thickMasterSpecial = useSelector(state => state.app.listThickStandardSpecial);

    const [modal, contextHolder] = Modal.useModal();

    const [state, setState] = useState(null);
    const {
        workOrderDate,
        listRoll,
        listStandard,
        sizeThickness,
        sizeWidth,
        sizeWidthReal,
        md,
        positionRoll1,
        positionRoll2,
        sizeWidthVia,
        totalVia,
        ratioTrash,
        mass,
        weightAvg,
        id: idBlock,
        catDau,
    } = state ?? {};
    const rollQty = listRoll?.filter(i => !!i.rollId).length || 0;

    //roll của các block khác (không cho user chọn trùng roll)
    const [otherRollBlock, set_otherRoll] = useState([]);

    const [error, setError] = useState(false);

    const rollOption = useMemo(
        () => rollMaster?.filter(item => (!sizeThickness || item.sizeDepth.toString() == sizeThickness) && (!sizeWidth || item.sizeWidth.toString() == sizeWidth)) || [],
        [rollMaster, sizeWidth, sizeThickness],
    );

    // filter input để tìm cuộn dễ hơn
    const [filterThickness, set_filterThickness] = useState('');
    const [filterWidth, set_filterWidth] = useState('');
    const [errFilter, set_errFilter] = useState(false);
    const [invalidVia, set_invalidVia] = useState(false);

    const addRoll = () => {
        setState(
            produce(state => {
                state.listRoll.push({ ...initialRoll, id: `roll_${state.listRoll.length}` });
            }),
        );
    };
    const deleteRoll = idCuon => {
        setState(
            produce(state => {
                var iRoll = state.listRoll.findIndex(i => i.id == idCuon);
                if (iRoll > 0) state.listRoll.splice(iRoll, 1);
            }),
        );
    };
    //prettier-ignore
    const editRoll = (idCuon, field, keyboardType = 'none') => event => {
        let val = ['string' ,'number'].includes(typeof event) ? event : event.target?.value
        if (keyboardType == 'numeric') {
            val = val.replace(/[^0-9.]/g, '');
        }
        setState(produce(state => {
            var iRoll = state.listRoll.findIndex(i => i.id == idCuon);
            state.listRoll[iRoll][field] = val;
        }))
    }
    //prettier-ignore
    const editStandard = useCallback(
        (idStandard, field, keyboardType = 'none') => event => {
            let val = ['string' ,'number'].includes(typeof event) ? event : event.target?.value
            if (keyboardType == 'numeric') {
                val = val.replace(/[^0-9.]/g, '');
            }
        
            setState(produce(state => {
                var iStand = state.listStandard.findIndex(i => i.id == idStandard);
                state.listStandard[iStand][field] = val;
                // lstBlock.find(item => item.id == idCuon)[field] = val;
            }));
        },[]);

    const addStandard = () => {
        setState(
            produce(state => {
                var thickValue = state.listStandard[0]?.thicknessProduct || 0;
                state.listStandard.push({
                    ...initialStandard,
                    id: `standard_${state.listStandard.length}`,
                    orderCode: 'D',
                    thicknessProduct: thickValue.toString(),
                    thicknessMin: (Number(thickValue) * 0.95).toFixed(2),
                });
            }),
        );
    };
    const deleteStandard = idStandard => {
        setState(
            produce(state => {
                var iStand = state.listStandard.findIndex(i => i.id == idStandard);
                if (iStand > 0) state.listStandard.splice(iStand, 1);
            }),
        );
    };
    const editBlock = useCallback(
        (field, keyboardType = 'none') =>
            event => {
                let val = ['string', 'number', 'boolean'].includes(typeof event) ? event : event.target?.value;
                if (keyboardType == 'numeric') {
                    val = val.replace(/[^0-9.]/g, '');
                }
                setState(prev => ({ ...prev, [field]: val }));
            },
        [],
    );
    const columns_cuon = [
        {
            title: 'Mã cuộn',
            dataIndex: 'rollId',
            key: 'rollId',
            width: 170,
            render: (value, record, index) => {
                var otherRollInBlock = listRoll.filter(i => i.rollId != value).map(i => i.rollId);
                var otherRoll = otherRollBlock.concat(otherRollInBlock);
                const options = rollOption.filter(i => !otherRoll.includes(i.id));
                return (
                    <Select
                        className={cn(style['table-select'], error && value == '' && style['warning-select'])}
                        options={options}
                        onChange={value => onSelectRoll(record.id, value)}
                        value={value}
                        optionFilterProp="label"
                        dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        showSearch
                        listHeight={160}
                        //allowClear
                    />
                );
            },
        },
        {
            title: 'TS',
            dataIndex: 'ts',
            key: 'ts',
            width: 100,
            render: (value, record) => <Input className={style['table-input']} value={value} onChange={editRoll(record.id, 'ts', 'numeric')} />,
        },
        {
            title: 'Dày x rộng (mm)',
            dataIndex: 'thickWidth',
            key: 'thickWidth',
            width: 125,
            render: (value, record) => <Input className={style['table-input']} value={value} disabled />,
        },
        {
            title: 'Khối lượng (kg)',
            dataIndex: 'weight',
            key: 'weight',
            width: 120,
            render: (value, record) => <Input className={style['table-input']} value={value} disabled />,
        },
        {
            title: 'Ngày nhập kho',
            dataIndex: 'importDate',
            key: 'importDate',
            width: 120,
            render: (value, record) => <Input className={style['table-input']} disabled value={value} />,
        },
        {
            title: 'Loại thép',
            dataIndex: 'steelType',
            key: 'steelType',
            width: 120,
            render: (value, record) => <Input className={style['table-input']} disabled value={value} />,
        },
        {
            title: 'Mác thép',
            dataIndex: 'steelGrade',
            key: 'steelGrade',
            width: 120,
            render: (value, record) => <Input className={style['table-input']} disabled value={value} />,
        },

        {
            title: '',
            key: 'option',
            width: 80,
            align: 'center',
            render: (value, item, index) => (
                <Space>
                    <div className={style['table-button']} onClick={() => addRoll()}>
                        <PlusOutlined style={{ color: '#2c3782', fontSize: '12px' }} />
                    </div>
                    <div className={style['table-button']} onClick={() => deleteRoll(item.id)}>
                        <MinusOutlined style={{ color: '#2c3782', fontSize: '12px' }} />
                    </div>
                </Space>
            ),
        },
    ];

    const columns_xe = [
        {
            title: 'Đơn hàng',
            dataIndex: 'orderCode',
            key: 'orderCode',
            width: 110,
            align: 'center',
            render: (text, item, index) => {
                const onChangeDonHang = value => {
                    editStandard(item.id, 'orderCode')(value);
                    if (value != text) {
                        var sMaster = value == 'DB' ? standardMasterSpecial : standardMaster;
                        var listBody = sMaster?.find(i => i.value == item.typeProduct)?.lstBody || [];
                        if (listBody.length > 0) {
                            var do_rong_xe = listBody.find(i => i.specificSize.toString() == item.thicknessProduct)?.sizeThickness || '';
                            editStandard(item.id, 'sizeWidthTape')(do_rong_xe);
                        } else {
                            editStandard(item.id, 'sizeWidthTape')('');
                            editStandard(item.id, 'typeProduct')('');
                        }
                    }
                };
                return (
                    <Select
                        className={cn(style['table-select'], error && text == '' && style['warning-select'])}
                        options={ORDER_OPTION}
                        onChange={onChangeDonHang}
                        value={text}
                        optionFilterProp="label"
                        dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                    />
                );
            },
        },
        {
            title: (
                <div>
                    <div>Dày sp*</div>
                    <div>{'(mm)'}</div>
                </div>
            ),
            dataIndex: 'thicknessProduct',
            key: 'thicknessProduct',
            width: '90px',
            align: 'center',
            render: (text, item, index) => {
                var sMaster = item.orderCode == 'DB' ? standardMasterSpecial : standardMaster;
                const onChangeText = ({ target }) => {
                    var value = target.value.replace(/[^0-9.]/g, '');
                    editStandard(item.id, 'thicknessProduct')(value);
                    editStandard(item.id, 'thicknessMin')((Number(value) * 0.95).toFixed(2));
                    var listBody = sMaster?.find(i => i.value == item.typeProduct)?.lstBody || [];
                    if (listBody.length > 0) {
                        var do_rong_xe = listBody.find(i => i.specificSize.toString() == value)?.sizeThickness || '';
                        editStandard(item.id, 'sizeWidthTape')(do_rong_xe);
                    }
                };
                return <Input className={cn(style['table-input'], error && text == '' && style['warning-input'])} value={text} onChange={onChangeText} />;
            },
        },
        {
            title: (
                <div>
                    <div>Dày MIN</div>
                    <div>{'(mm)'}</div>
                </div>
            ),
            dataIndex: 'thicknessMin',
            key: 'thicknessMin',
            width: '90px',
            align: 'center',
            render: (text, item, index) => {
                return (
                    <Input
                        className={cn(style['table-input'])}
                        value={item.thicknessMin || ''}
                        disabled
                        // onChange={onChangeText}
                    />
                );
            },
        },
        {
            title: (
                <div>
                    <div>Loại sản phẩm</div>
                    <div>{'(mm)'}</div>
                </div>
            ),
            dataIndex: 'typeProduct',
            key: 'typeProduct',
            width: '100px',
            align: 'center',
            render: (text, item, index) => {
                var sMaster = item.orderCode == 'DB' ? standardMasterSpecial : standardMaster;
                var onSelect = value => {
                    if (!!value) {
                        var listBody = sMaster?.find(i => i.value == value)?.lstBody || [];
                        var do_rong_xe = '';
                        if (listBody.length > 0) {
                            do_rong_xe = listBody.find(i => i.specificSize.toString() == item.thicknessProduct)?.sizeThickness || '';
                        }
                        editStandard(item.id, 'sizeWidthTape')(do_rong_xe);
                        editStandard(item.id, 'typeProduct')(value);
                    }
                };
                return (
                    <Select
                        className={cn(style['table-select'], error && text == '' && style['warning-select'])}
                        options={sMaster || []}
                        onChange={onSelect}
                        value={text}
                        optionFilterProp="label"
                        dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        showSearch
                        listHeight={160}
                        onSearch={onSelect}
                    />
                );
            },
        },
        {
            title: (
                <div>
                    <div>Rộng*</div>
                    <div>{'(mm)'}</div>
                </div>
            ),
            dataIndex: 'sizeWidthTape',
            key: 'sizeWidthTape',
            width: '80px',
            align: 'center',
            render: (text, item, index) => {
                // var err_xe = errors.includes(`${idCuon}-${item.id_xe}`);
                var err_xe = false;
                const onChangeText = ({ target }) => {
                    var widthNum = Number(target.value.replace(/[^0-9.]/g, '')) || 0;
                    var stripNum = Number(item.stripNumber) || 0;
                    editStandard(item.id, 'sizeWidthTape', 'numeric')(target.value);
                    editStandard(item.id, 'totalWidth')((widthNum * stripNum).toFixed(2));
                };
                return <Input className={cn(style['table-input'], error && text == '' && style['warning-input'])} value={text} onChange={onChangeText} />;
            },
        },
        {
            title: 'Số dải*',
            dataIndex: 'stripNumber',
            align: 'center',
            key: 'stripNumber',
            width: '70px',
            render: (text, item, index) => {
                var err_xe = false;
                const onChangeText = ({ target }) => {
                    var stripNum = Number(target.value.replace(/[^0-9.]/g, '')) || 0;
                    var widthNum = Number(item.sizeWidthTape) || 0;
                    editStandard(item.id, 'stripNumber')(stripNum.toString());
                    editStandard(item.id, 'totalWidth')((widthNum * stripNum).toFixed(2));
                };
                return <Input className={cn(style['table-input'], error && text == '' && style['warning-input'])} value={text} onChange={onChangeText} />;
            },
        },
        {
            title: (
                <div>
                    <div>Tổng dộ rộng</div>
                    <div>{'(mm)'}</div>
                </div>
            ),
            dataIndex: 'totalWidth',
            align: 'center',
            key: 'totalWidth',
            width: '100px',
            render: (text, item, index) => <Input disabled className={style['table-input']} value={text} />,
        },
        {
            title: (
                <div>
                    <div>Khối lượng tính</div>
                    <div>{'(kg)'}</div>
                </div>
            ),
            dataIndex: 'weightCal',
            align: 'center',
            key: 'weightCal',
            width: '100px',
            render: (text, item, index) => {
                var numValue = (Number(weightAvg) * rollQty * Number(item.sizeWidthTape) * Number(item.stripNumber)) / Number(sizeWidthReal);
                return <Input disabled className={cn(style['table-input'])} value={isNaN(numValue) || numValue == Infinity ? 0 : numValue.toFixed(2)} />;
            },
        },
        {
            title: 'Ghi chú',
            dataIndex: 'note',
            key: 'note',
            width: '220px',
            align: 'center',
            render: (text, item, index) => (
                <TextArea
                    style={{ padding: '3px' }}
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    className={style['table-input']}
                    value={item.note}
                    onChange={editStandard(item.id, 'note')}
                />
            ),
        },
        {
            title: '',
            key: 'option',
            width: '0px',
            align: 'center',
            render: (value, item, index) => (
                <Space>
                    <div className={style['table-button']} onClick={() => addStandard()}>
                        <PlusOutlined style={{ color: '#2c3782', fontSize: '12px' }} />
                    </div>
                    <div className={style['table-button']} onClick={() => deleteStandard(item.id)}>
                        <MinusOutlined style={{ color: '#2c3782', fontSize: '12px' }} />
                    </div>
                </Space>
            ),
        },
    ];
    //chọn cuộn sẽ tự động fill thuộc tính của cuộn
    const onSelectRoll = (idCuon, rollId) => {
        const roll = rollOption.find(item => item.id == rollId);
        if (!roll) return;

        var weightArr = listRoll
            .filter(i => !!i.rollId)
            .map(i => Number(i.weight))
            .concat(Number(roll.netWeight));
        editBlock('weightAvg')(mean(weightArr).toFixed(2));

        editRoll(idCuon, 'rollId')(rollId);
        editRoll(idCuon, 'steelType')(roll.steelType || '');
        editRoll(idCuon, 'steelGrade')(roll.steelGrade || '');
        editRoll(idCuon, 'weight')(roll.netWeight || '');
        editRoll(idCuon, 'importDate')(roll.importDate || '');
        editRoll(idCuon, 'nsmvCoilId')(roll.nsmvCoilId || '');
        editRoll(idCuon, 'thickWidth')(roll.sizeDepth + 'x' + roll.sizeWidth);

        for (const xe of listStandard) {
            var value = roll.sizeDepth;
            console.log('Current value', value);
            var sMaster = xe.orderCode == 'DB' ? standardMasterSpecial : standardMaster;
            editStandard(xe.id, 'thicknessProduct')(value);
            editStandard(xe.id, 'thicknessMin')((Number(value) * 0.95).toFixed(2));
            var listBody = sMaster?.find(i => i.value == xe.typeProduct)?.lstBody || [];
            if (listBody.length > 0) {
                var do_rong_xe = listBody.find(i => i.specificSize.toString() == value)?.sizeThickness || '';
                editStandard(xe.id, 'sizeWidthTape')(do_rong_xe);
            }
        }
    };

    const onPressFilter = () => {
        var arrThickWidth = listRoll.filter(i => !!i.rollId).map(i => i.thickWidth);
        var filterSize = filterThickness + 'x' + filterWidth;

        if (!filterWidth || !filterThickness) {
            set_errFilter(true);
            notification.error({ message: 'Nhập đầy đủ dày/rộng' });
            return;
        }
        if (rollQty > 0 && !arrThickWidth.includes(filterSize)) {
            modal.confirm({
                title: 'Xác nhận thay đổi dày rộng',
                icon: <ExclamationCircleOutlined />,
                content: 'Sau khì thay đổi dày/rộng, toàn bộ cuộn có kích thước dày/rộng cũ sẽ bị xoá đi khỏi danh sách xẻ. Tiếp tục?',
                okText: 'OK',
                okType: 'danger',
                onOk: () => changeThickWidth(true),
                cancelText: 'Đóng',
            });
        } else changeThickWidth();
    };
    const changeThickWidth = (reset = false) => {
        if (reset) {
            setState(prev => ({ ...prev, listRoll: [initialRoll] }));
        }
        editBlock('sizeWidth')(filterWidth);
        editBlock('sizeThickness')(filterThickness);
        editBlock('sizeWidthReal')((Number(filterWidth) + 10).toString());
        editBlock('md')('D');
    };

    const calc_via_width = useMemo(() => {
        var value = Number(sizeWidth) - sumBy(listStandard, i => Number(i.sizeWidthTape) * Number(i.stripNumber));
        return isNaN(value) || value == Infinity ? '0' : value.toFixed(2);
    }, [sizeWidth, listStandard]);
    useEffect(() => {
        editBlock('sizeWidthVia')(Number(calc_via_width));
        var invalid = Number(calc_via_width) < MAX_NEGATIVE_WIDTH_TAPE;
        editBlock('errorVia')(invalid);
        if (invalid) {
            set_invalidVia(true);
        } else set_invalidVia(false);
    }, [calc_via_width]);

    const calc_via_weight = useMemo(() => {
        var value = (Number(weightAvg) * rollQty * (Number(calc_via_width) - Number(sizeWidth) + Number(sizeWidthReal))) / Number(sizeWidthReal);
        return isNaN(value) || value == Infinity ? '0' : value.toFixed(2);
    }, [calc_via_width, sizeWidth, sizeWidthReal, weightAvg, rollQty]);
    useEffect(() => {
        editBlock('mass')(Number(calc_via_weight));
    }, [calc_via_weight]);

    const calc_via_total = useMemo(() => {
        if (!catDau) return calc_via_weight;
        var length = Math.round((Number(weightAvg) * 99) / (Number(sizeThickness) * Number(sizeWidth) * 0.785));
        var viaWeight = Math.round((length * 0.785 * Number(catDau) * Number(sizeThickness)) / 1000);
        var totalVia = Number(calc_via_weight) + viaWeight;
        return Math.round(totalVia);
    }, [catDau, weightAvg, sizeThickness, sizeWidth, calc_via_weight]);
    useEffect(() => {
        editBlock('totalVia')(Number(calc_via_total));
    }, [calc_via_total]);

    const calc_via_rate = useMemo(() => {
        var val = (calc_via_total / (Number(weightAvg) * rollQty)) * 100;
        return isNaN(val) || val == Infinity ? '0' : val.toFixed(2);
    }, [calc_via_total, weightAvg, rollQty]);
    useEffect(() => {
        editBlock('ratioTrash')(Number(calc_via_rate));
    }, [calc_via_rate]);

    useImperativeHandle(ref, () => ({
        openEdit(data, otherRoll) {
            console.log('Raw data', data);
            const transformed = buildEditBlock(data);
            console.log('Trans data', transformed);
            set_visible(true);
            setState(transformed);
            set_otherRoll(otherRoll);
            set_filterThickness(transformed.sizeThickness);
            set_filterWidth(transformed.sizeWidth);
        },
    }));

    const validateForm = () => {
        let errorCode = '';
        if (!sizeWidth || !sizeWidthReal || !sizeThickness) {
            errorCode = errorCode || 'empty';
        }
        var iRoll = listRoll.findIndex(roll => !roll.rollId);
        if (iRoll > -1) {
            errorCode = errorCode || 'roll_empty';
        }
        if (uniqBy(listRoll, 'thickWidth').length > 1) {
            errorCode = errorCode || 'not_same_size';
        }
        var iStand = listStandard.findIndex(stand => !stand.thicknessProduct || !stand.typeProduct || !stand.sizeWidthTape || !stand.stripNumber || !stand.orderCode);
        if (iStand > -1) {
            errorCode = errorCode || 'empty';
        }

        if (errorCode == 'empty') {
            setError(true);
            notification.error({ message: 'Chưa nhập đủ thông số' });
            return false;
        } else if (errorCode == 'roll_empty') {
            setError(true);
            notification.error({ message: 'Chưa chọn cuộn để xẻ' });
            return false;
        } else if (errorCode == 'not_same_size') {
            setError(true);
            notification.error({ message: 'Cuộn phải có cùng kích thước dày/rộng' });
            return false;
        } else if (invalidVia) {
            notification.error({ message: 'Thông số chưa hợp lệ. Vui lòng nhập lại' });
            return false;
        }
        return true;
    };

    const onSaveChange = () => {
        if (!validateForm()) return;
        console.log('New state: ', state);
        saveEdit(idBlock, state);
        closeEdit();
    };

    const closeEdit = () => {
        set_visible(false);
        setState(null);
    };
    return (
        <Modal
            title="Sửa yêu cầu"
            closeIcon={false}
            open={visible}
            onCancel={closeEdit}
            className={style['modal-container']}
            width={'82vw'}
            style={{ top: 40 }}
            footer={[
                <Button key="back" onClick={closeEdit}>
                    Đóng
                </Button>,
                <Button type="primary" onClick={onSaveChange}>
                    Lưu thay đổi
                </Button>,
            ]}
        >
            {!!state && (
                <div className={style['main']}>
                    <Row className={style['row-input']} style={{ rowGap: '30px' }}>
                        <Col span={2} className={style['block-title']}>
                            Ngày lệnh
                        </Col>
                        <Col span={4} className={cn(style['block-title'])}>
                            <DatePicker
                                format={'DD/MM/YYYY'}
                                className={cn(style['block-input'], error && workOrderDate == '' && style['warning-input'])}
                                placeholder=""
                                value={!!workOrderDate && workOrderDate !== '' ? dayjs(workOrderDate, 'DD/MM/YYYY') : undefined}
                                onChange={(date, dateStr) => editBlock('workOrderDate')(dateStr)}
                            />
                        </Col>
                    </Row>
                    <h4 style={{ color: 'red', marginTop: '14px' }}>Chọn tôn cuộn </h4>
                    <Row className={style['row-input']} style={{ rowGap: '30px' }}>
                        <Col span={2} className={style['filter-title']}>
                            {'Dày x rộng (mm)'}
                        </Col>
                        <Col span={4}>
                            <Row style={{ justifyContent: 'space-between', width: '85%', alignItems: 'center' }}>
                                <Input
                                    className={cn(style['small-input'], (errFilter || error) && filterThickness == '' && style['warning-input'])}
                                    value={filterThickness}
                                    onChange={({ target }) => set_filterThickness(target.value.replace(/[^0-9.]/g, ''))}
                                />
                                <h5>x</h5>
                                <Input
                                    className={cn(style['small-input'], (errFilter || error) && filterWidth == '' && style['warning-input'])}
                                    value={filterWidth}
                                    onChange={({ target }) => set_filterWidth(target.value.replace(/[^0-9.]/g, ''))}
                                />
                            </Row>
                        </Col>
                        <Col span={3}>
                            <Button className={style['filter-button']} style={{ width: '80%' }} onClick={onPressFilter}>
                                <SearchOutlined style={{ color: 'white' }} />
                                Tìm kiếm
                            </Button>
                        </Col>
                        <Col span={15} />
                        <Col span={2} className={style['filter-title']}>
                            {'Rộng thực tế (mm)'}
                        </Col>
                        <Col span={4}>
                            <Input
                                className={cn(style['filter-input'], !error && sizeWidthReal == '' && style['warning-input'])}
                                value={sizeWidthReal}
                                onChange={editBlock('sizeWidthReal', 'numeric')}
                            />
                        </Col>
                        <Col span={2} className={style['filter-title']}>
                            {'M/D'}
                        </Col>
                        <Col span={4}>
                            <Input className={cn(style['filter-input'])} value={md} onChange={editBlock('md')} />
                        </Col>
                        <Col span={2} className={style['filter-title']}>
                            {'Vị trí cuộn (H)'}
                        </Col>
                        <Col span={4}>
                            <Input className={cn(style['filter-input'])} value={positionRoll1} onChange={editBlock('positionRoll1')} />
                        </Col>
                        <Col span={2} className={style['filter-title']}>
                            {'Vị trí cuộn (K)'}
                        </Col>
                        <Col span={4}>
                            <Input className={cn(style['filter-input'])} value={positionRoll2} onChange={editBlock('positionRoll2')} />
                        </Col>
                        <Col span={2} className={style['filter-title']}>
                            {'Cắt đầu'}
                        </Col>
                        <Col span={4}>
                            <Input className={cn(style['filter-input'])} value={catDau} onChange={editBlock('catDau', 'numeric')} />
                        </Col>
                    </Row>
                    <Table
                        style={{ marginTop: '20px' }}
                        columns={columns_cuon}
                        dataSource={listRoll}
                        className={cn(style['table'], error && (rollQty == 0 || uniqBy(listRoll, 'thickWidth').length > 1) && style['warning-input'])}
                        rowKey="key"
                        scroll={{ x: sumBy(columns_cuon, 'width') }}
                        pagination={false}
                        //pagination={{ position: ['none', 'none'], pageSize: 500000 }}
                    />

                    <Row style={{ marginTop: '20px' }}>
                        <Col span={4} className={style['filter-title']}>
                            Số lượng cuộn: {rollQty}
                        </Col>
                        <Col span={6} className={style['filter-title']}>
                            Khối lượng trung bình: {weightAvg || 0}kg
                        </Col>
                    </Row>

                    <h4 style={{ color: 'red', marginTop: '25px' }}>Quy cách xẻ</h4>
                    <Table
                        columns={columns_xe}
                        dataSource={listStandard}
                        className={style['table']}
                        rowKey="key"
                        // ref={table}
                        scroll={{ y: listStandard?.length > 10 ? 580 : null }}
                        pagination={false}
                        //pagination={{ position: ['none', 'none'], pageSize: 500000 }}
                    />

                    <h4 style={{ color: 'red', marginTop: '20px' }}>{Number(calc_via_width) > 60 ? 'Rộng còn lại' : 'Via'}</h4>
                    <Row className={style['row-input']}>
                        <Col span={2} className={style['filter-title']}>
                            {'Rộng thực tế (mm)'}
                        </Col>
                        <Col span={4}>
                            <Input disabled className={cn(style['filter-input'], invalidVia && style['warning-input'])} value={calc_via_width} />
                            <h5 style={{ color: 'red', marginTop: '5px' }}>{invalidVia ? 'Vượt quá tiêu chuẩn' : ''}</h5>
                        </Col>
                        <Col span={2} className={style['filter-title']}>
                            {'Khối lượng via (kg)'}
                        </Col>
                        <Col span={4}>
                            <Input disabled className={style['filter-input']} value={calc_via_weight} />
                        </Col>
                        <Col span={2} className={style['filter-title']}>
                            {'Tổng via (kg)'}
                        </Col>
                        <Col span={4}>
                            <Input disabled className={style['filter-input']} value={calc_via_total} />
                        </Col>
                        <Col span={2} className={style['filter-title']}>
                            Tỷ lệ phế
                        </Col>
                        <Col span={4}>
                            <Input disabled className={style['filter-input']} value={`${calc_via_rate}%`} />
                        </Col>
                    </Row>
                    {contextHolder}
                </div>
            )}
        </Modal>
    );
});

export default memo(EditYeuCauModal);
