const keys = {
  enable_app_log: true,
  section: "SECTION",

  api_request_status_success_code: 200,
  api_message_EMPTY: "EMPTY",
  api_message_USER_NOTFOUND: "NOT_FOUND_USER",
  api_message_VALID: "VALID",

  api_login_fail_user: 'USERNAME_OR_PASSWORD_INCORRECT',

  userRoles:{
    KhoTonCuon: "T",
    KhoTonCuon_Request : "TR",// role thường
    KhoTonCuon_PheDuyet : "TP",// role quản lý
  },

  //feature
  main_tong_quan : "main_tong_quan",
  main_danh_sach_ton_kho: "main_danh_sach_ton_kho", 

  main_danh_sach_nhap_kho: "main_danh_sach_nhap_kho",
  main_them_moi_nhap_kho: "main_them_moi_nhap_kho",
  main_chi_tiet_chi_thi_nhap_kho: "main_chi_tiet_chi_thi_nhap_kho",

  main_xuat_kho: "main_xuat_kho",
  main_danh_sach_xuat_kho: "main_danh_sach_xuat_kho",
  main_danh_sach_chi_thi_xuat_kho: "main_danh_sach_chi_thi_xuat_kho",
  main_them_moi_xuat_kho: "main_them_moi_xuat_kho",
  main_chi_tiet_xuat_kho: "main_chi_tiet_xuat_kho",
  main_ds_yeu_cau_xe_bang: "main_ds_yeu_cau_xe_bang",

  main_kiem_ke: "main_kiem_ke",
  main_them_moi_chi_thi_kiem_ke: "main_them_moi_chi_thi_kiem_ke",
  main_danh_sach_chi_thi_kiem_ke: "main_danh_sach_chi_thi_kiem_ke",

  main_chuyen_kho: "main_chuyen_kho",


  main_bao_cao: "main_bao_cao",
  main_bao_cao_thong_ke: "main_bao_cao_thong_ke",
  main_bao_cao_xuat_file: "main_bao_cao_xuat_file",

  main_cai_dat: "main_cai_dat", 

  // feature
  quan_ly_tiep_don: "quan_ly_tiep_don",
  quan_ly_danh_muc: "quan_ly_danh_muc",
  quan_ly_vien_phi: "quan_ly_vien_phi",
  quan_ly_kham_benh: "quan_ly_kham_benh",
  quan_ly_chan_doan_hinh_anh_ris: "quan_ly_chan_doan_hinh_anh_ris",
  quan_ly_bhyt: "quan_ly_bhyt",
  quan_ly_duoc_ngoai_tru: "quan_ly_duoc_ngoai_tru",
  quan_ly_xep_hang_qms: "quan_ly_xep_hang_qms",

  main_quan_ly: "main_quan_ly",
  quy_chuan_bang_xe: "quy_chuan_bang_xe",
  quy_chuan_bang_xe_special: "quy_chuan_bang_xe_special",

  // local storage
  access_token: "access_token",
  expire_in: "expire_in",
  user_data: "user_data",
  key_loginTime: "key_loginTime",
  warehouse_name: "warehouse_name",
  yeucau_bangxe: "yeucau_bangxe",
  malenh_bangxe: "malenh_bangxe",

  // danh sách danh mục
  dm_benh_vien: "dm_benh_vien", // danh mục bệnh viện
  dm_khoa: "dm_khoa", // danh mục khoa
  dm_phong: "dm_phong", // danh mục phòng
  dm_dich_vu_ki_thuat: "dm_dich_vu_ki_thuat", // danh mục dịch vụ kĩ thuật
  dm_nhom_dv_ki_thuat: "dm_nhom_dv_ki_thuat", // danh mục nhóm dịch vụ kĩ thuật
  dm_loai_dv_ki_thuat: "dm_loai_dv_ki_thuat", // danh mục loại dịch vụ kĩ thuật
  dm_nhom_hoa_don: "dm_nhom_hoa_don", // danh mục nhóm hóa đơn
  dm_thuoc: "dm_thuoc", // danh mục thuốc
  dm_nha_thuoc: "dm_nha_thuoc", // danh mục nhà thuốc
  dm_hang_san_xuat: "dm_hang_san_xuat", // danh mục hãng sản xuất
  dm_duong_dung: "dm_duong_dung", // danh mục đường dùng
  dm_kho_tu_truc: "dm_kho_tu_truc", // danh mục kho, tủ trực
};

export default keys;
