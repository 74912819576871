import React, { useState, useMemo, useRef, memo, useEffect } from 'react';
import { Button, Checkbox, notification, Row, Table, Modal } from 'antd';
import style from './danhSachYeuCauXeBang.module.less';
import { EditOutlined, DownOutlined, RightOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { buildExcelRequest, flattenRequestV2 } from '../ThemMoiNhapKho/utils';
import { produce } from 'immer';
import { useDispatch } from 'react-redux';
import { appLoading } from '../../../../ducks/slices/appSlice';
import AxiosService from '../../../../common/AxiosService';
import { apis, keys } from '../../../../constants';
import { formatWeightText, localGet } from '../../../../helpers';
import EditYeuCau from './EditYeuCau/EditYeuCau';
import { revertEditBlock } from '../ThemMoiNhapKho/utils';
import { useSelector } from 'react-redux';

const TableYeuCauV2 = ({ data, refetchData }) => {
    const [modal, contextHolder] = Modal.useModal();
    const rollMaster = useSelector(state => state.app.listRollManufacture);

    const columns = [
        {
            title: 'Chọn in',
            align: 'center',
            dataIndex: 'isPrint',
            key: 'isPrint',
            width: 60,
            render: (value, record) => (value != undefined ? <Checkbox onChange={() => toogleSelectPrint(record.blockId)} checked={value} /> : null),
        },
        {
            title: 'Tiến trình',
            className: style['row-header'],
            children: [
                {
                    title: 'Ngày lệnh',
                    children: [
                        {
                            title: ' ',
                            dataIndex: 'workOrderDate',
                            key: 'workOrderDate',
                            width: 100,
                            render: (value, record) => value,
                        },
                    ],
                },
            ],
        },
        {
            title: 'STT No.',
            dataIndex: 'no',
            key: 'no',
            width: 70,
            render: (value, record) => value,
        },
        {
            title: 'Tôn cuộn',
            children: [
                {
                    title: 'Mã cuộn',
                    children: [
                        {
                            title: ' ',
                            dataIndex: 'nsmvCoilId',
                            key: 'nsmvCoilId',
                            width: 150,
                            render: (value, record) => <div className={style['important-text']}>{value}</div>,
                        },
                    ],
                },
                {
                    title: 'Loại',
                    children: [
                        {
                            title: ' ',
                            dataIndex: 'steelType',
                            key: 'steelType',
                            width: 100,
                            render: (value, record) => value,
                        },
                    ],
                },
                {
                    title: 'TS',
                    children: [
                        {
                            title: ' ',
                            dataIndex: 'ts',
                            key: 'ts',
                            width: 100,
                            render: (value, record) => value,
                        },
                    ],
                },
                {
                    title: 'Vị trí cuộn',
                    children: [
                        {
                            title: 'H',
                            align: 'center',
                            dataIndex: 'positionRoll1',
                            key: 'positionRoll1',
                            width: 80,
                            render: (value, record) => value,
                        },
                        {
                            title: 'K',
                            align: 'center',
                            dataIndex: 'positionRoll2',
                            key: 'positionRoll2',
                            width: 80,
                            render: (value, record) => value,
                        },
                    ],
                },
                {
                    title: 'M/D',
                    children: [
                        {
                            title: ' ',
                            dataIndex: 'md',
                            key: 'md',
                            width: 80,
                            render: (value, record) => value,
                        },
                    ],
                },
                {
                    title: 'Dày',
                    children: [
                        {
                            title: 'mm',
                            dataIndex: 'sizeThickness',
                            key: 'sizeThickness',
                            width: 80,
                            render: (value, record) => formatWeightText(value),
                        },
                    ],
                },
                {
                    title: 'Rộng',
                    children: [
                        {
                            title: 'mm',
                            dataIndex: 'sizeWidth',
                            key: 'sizeWidth',
                            width: 80,
                            render: (value, record) => formatWeightText(value),
                        },
                    ],
                },
                {
                    title: 'Rộng thực tế',
                    children: [
                        {
                            title: 'mm',
                            dataIndex: 'sizeWidthReal',
                            key: 'sizeWidthReal',
                            width: 100,
                            render: (value, record) => formatWeightText(value),
                        },
                    ],
                },
                {
                    title: 'Khối lượng',
                    children: [
                        {
                            title: 'kg',
                            dataIndex: 'weight',
                            key: 'weight',
                            width: 100,
                            render: (value, record) => formatWeightText(value),
                        },
                    ],
                },
            ],
        },
        {
            title: 'Quy cách',
            children: [
                {
                    title: 'Dày MIN',
                    children: [
                        {
                            title: 'mm',
                            dataIndex: 'thicknessMin',
                            key: 'thicknessMin',
                            width: 100,
                        },
                    ],
                },
                {
                    title: 'Dày sp',
                    children: [
                        {
                            title: 'mm',
                            dataIndex: 'thicknessProduct',
                            key: 'thicknessProduct',
                            width: 100,
                            render: value => formatWeightText(value),
                        },
                    ],
                },
            ],
        },
        {
            title: 'Băng xẻ',
            children: [
                {
                    title: 'D/DB',
                    children: [
                        {
                            title: ' ',
                            dataIndex: 'orderCode',
                            key: 'orderCode',
                            width: 70,
                        },
                    ],
                },
                {
                    title: 'Rộng',
                    children: [
                        {
                            title: 'mm',
                            dataIndex: 'sizeWidthTape',
                            key: 'sizeWidthTape',
                            width: 80,
                            render: value => formatWeightText(value),
                        },
                    ],
                },
                {
                    title: 'Loại sản phẩm',
                    children: [
                        {
                            title: ' ',
                            dataIndex: 'typeProduct',
                            key: 'typeProduct',
                            width: 100,
                        },
                    ],
                },
                {
                    title: 'Số dải',
                    children: [
                        {
                            title: ' ',
                            dataIndex: 'stripNumber',
                            key: 'stripNumber',
                            width: 80,
                        },
                    ],
                },
                {
                    title: 'Tổng độ rộng',
                    children: [
                        {
                            title: 'mm',
                            dataIndex: 'totalWidth',
                            key: 'totalWidth',
                            width: 100,
                            render: value => formatWeightText(value),
                        },
                    ],
                },
                {
                    title: 'Khối lượng tính toán',
                    children: [
                        {
                            title: 'kg',
                            dataIndex: 'weightCal',
                            key: 'weightCal',
                            width: 100,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Via',
            children: [
                {
                    title: 'Rộng (TT)',
                    children: [
                        {
                            title: 'mm',
                            dataIndex: 'sizeWidthVia',
                            key: 'sizeWidthVia',
                            width: 100,
                            render: (value, record) => formatWeightText(value),
                        },
                    ],
                },

                {
                    title: 'Khối lượng',
                    children: [
                        {
                            title: 'kg',
                            dataIndex: 'mass',
                            key: 'mass',
                            width: 100,
                            render: (value, record) => formatWeightText(value),
                        },
                    ],
                },
                {
                    title: 'Tổng via',
                    children: [
                        {
                            title: 'kg',
                            dataIndex: 'totalVia',
                            key: 'totalVia',
                            width: 100,
                            render: (value, record) => formatWeightText(value),
                        },
                    ],
                },
                {
                    title: 'Tỷ lệ phế',
                    children: [
                        {
                            title: '%',
                            dataIndex: 'ratioTrash',
                            key: 'ratioTrash',
                            width: 100,
                            render: (value, record) => (!!value ? `${value}%` : ''),
                        },
                    ],
                },
            ],
        },
        {
            title: 'Ghi chú',
            align: 'center',
            dataIndex: 'note',
            key: 'note',
            width: 250,
        },
        {
            title: ' ',
            width: 110,
            fixed: 'right',
            render: (value, item, index) => {
                if (!item.showEdit) return null;
                return (
                    <Row justify={'center'}>
                        <div className={style['table-button']} onClick={() => openEditModal(item.blockId)}>
                            {' '}
                            <EditOutlined />
                        </div>
                        <div className={style['table-button']} onClick={() => deleteBlock(item.blockId)}>
                            {' '}
                            <DeleteOutlined />
                        </div>
                    </Row>
                );
            },
        },
    ];
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [isEdited, setEdited] = useState(false);

    const modalRef = useRef();

    const [editState, setEdit] = useState([]);
    const openEditModal = blockId => {
        const blockEdit = editState.find(i => i.id == blockId);
        // console.log('Master', rollMaster);
        if (blockEdit) {
            const rollMasterId = rollMaster.map(i => i.id);
            const listRollId = blockEdit.lstBlock.map(item => item.rollId);
            if (listRollId.some(id => !rollMasterId.includes(id))) {
                notification.error({ message: 'Không thể thay đổi cuộn đã được nhập kho xẻ' });
            } else {
                const rollOtherblock = editState
                    .filter(i => i.id != blockId)
                    .map(i => i.lstBlock.map(roll => roll.rollId))
                    .flat();
                modalRef.current?.openEdit(blockEdit, rollOtherblock);
            }
        }
    };
    useEffect(() => {
        setEdit(data.lstBlockParent.map(item => ({ ...item, isPrint: false })));
    }, [data.lstBlockParent]);

    //prettier-ignore
    const editDataByBlockId = (blockId, modalData) => {
        const {workOrderDate, lstBlock} = revertEditBlock(modalData);
        console.log("New block: ", lstBlock)
        setEdited(true);
        setEdit(produce(state => {
            var iBlock = state.findIndex(i => i.id == blockId);
            if(iBlock > -1) {
                state[iBlock].workOrderDate = workOrderDate;
                state[iBlock].lstBlock = lstBlock;
            }
        }))
    }
    const deleteBlock = blockId => {
        if (editState.length > 1) {
            const blockEdit = editState.find(i => i.id == blockId);
            if (blockEdit) {
                const rollMasterId = rollMaster.map(i => i.id);
                const listRollId = blockEdit.lstBlock.map(item => item.rollId);
                if (listRollId.some(id => !rollMasterId.includes(id))) {
                    notification.error({ message: 'Không thể xoá cuộn đã được nhập kho xẻ' });
                } else {
                    modal.confirm({
                        title: 'Xác nhận xoá',
                        icon: <ExclamationCircleOutlined />,
                        content: 'Xoá ngày lệnh này?',
                        okText: 'OK',
                        okType: 'danger',
                        onOk: () => {
                            setEdited(true);
                            setEdit(prev => prev.filter(i => i.id != blockId));
                        },
                        cancelText: 'Đóng',
                    });
                }
            }
        }
    };

    const tableDataSource = flattenRequestV2(editState);

    //chọn in cho từng block (ngày lệnh)
    const toogleSelectPrint = blockId => {
        setEdit(
            produce(draft => {
                var selectIndex = draft.findIndex(i => i.id == blockId);
                draft[selectIndex].isPrint = !draft[selectIndex].isPrint;
            }),
        );
    };

    //xử lý in yêu cầu
    const [showPrint, set_showPrint] = useState(false);
    const onPressPrint = e => {
        if (isOpen) e.stopPropagation();
        if (!showPrint) {
            set_showPrint(true);
        } else {
            const printData = buildExcelRequest(editState).filter(item => item.isPrint === true);
            if (printData.length == 0) {
                notification.error({ message: 'Chưa chọn ngày lệnh để in' });
                return;
            }
            const dataSubmit = {
                id: null,
                productNo: data.productNo,
                workSplitDate: data.workSplitDate,
                lstBlockParent: printData,
            };
            console.log('Data to print', dataSubmit);
            dispatch(appLoading(true));
            fetch(apis.Url_Api_Post_InXeBang, {
                method: 'POST',
                body: JSON.stringify(dataSubmit),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localGet(keys.access_token),
                },
            })
                .then(response => response.blob())
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
                    dispatch(appLoading(false));
                    // Create an iframe element
                    const iframe = document.createElement('iframe');
                    iframe.style.display = 'none'; // Hide the iframe
                    iframe.src = url;
                    // Wait for the iframe to load the content
                    iframe.onload = function () {
                        // Invoke the print function of iframe's content
                        iframe.contentWindow.print();
                    };
                    // Append iframe to body
                    document.body.appendChild(iframe);
                    set_showPrint(false);
                })
                .catch(error => {
                    console.log('Error print Yêu cầu', error);
                    notification.error({ message: 'Có lỗi khi in yêu cầu' });
                    dispatch(appLoading(false));
                });
        }
    };

    const tableWidth = useMemo(() => {
        var width = 0;
        for (const col1 of columns) {
            if (!!col1.width) {
                width += col1.width;
            } else {
                for (const col2 of col1.children) {
                    var colW = col2.children[0]?.width || 0;
                    width += colW;
                }
            }
        }
        return width;
    }, []);

    //tải xuống file yêu cầu
    const downloadFileYeuCau = e => {
        e.stopPropagation();
        const transformState = buildExcelRequest(editState);
        const dataSubmit = {
            id: null,
            productNo: data.productNo,
            workSplitDate: data.workSplitDate,
            lstBlockParent: transformState,
        };
        dispatch(appLoading(true));

        fetch(apis.Url_Api_Post_DownloadXeBang, {
            method: 'POST',
            body: JSON.stringify(dataSubmit),
            headers: {
                'Content-Type': 'application/json',
                Authorization: localGet(keys.access_token),
            },
        })
            .then(response => response.blob())
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.download = 'YeuCauBangXe_' + data.workSplitDate.split('/').join('') + '.xlsx';
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                dispatch(appLoading(false));
            })
            .catch(err => {
                console.log('Error download', err);
                dispatch(appLoading(false));
                notification.error({ message: 'Tải yêu cầu thất bại' });
            });
    };

    //submit yêu cầu vừa sửa
    const submitEditYeuCau = async e => {
        e.stopPropagation();
        const transformState = editState.map(block => ({
            ...block,
            id: null,
            lstBlock: block.lstBlock.map(cuon => ({
                ...cuon,
                id: null,
                lstStandard: cuon.lstStandard.map(xe => ({ ...xe, id: null })),
            })),
        }));
        const dataSubmit = {
            id: data.id,
            productNo: data.productNo,
            workSplitDate: data.workSplitDate,
            lstBlockParent: transformState,
        };
        console.log('Data about to submit', dataSubmit);
        dispatch(appLoading(true));
        try {
            const { data } = await AxiosService.post(apis.Url_Api_Post_EditXeBang, dataSubmit);
            if (data.status == 200) {
                await refetchData();
                setEdited(false);
                setOpen(false);
                notification.success({ message: 'Sửa đổi yêu cầu thành công' });
            }
        } catch (e) {
            notification.error({ message: 'Sửa đổi yêu cầu thất bại' });
        } finally {
            dispatch(appLoading(false));
        }
    };

    const revertChange = e => {
        e.stopPropagation();
        setEdit(data.lstBlockParent.map(i => ({ ...i, isPrint: false })));
        setEdited(false);
    };

    return (
        <div style={{ marginTop: '50px' }}>
            <Row
                justify={'space-between'}
                style={{ alignItems: 'center' }}
                className={style['row-item']}
                onClick={() => {
                    setOpen(!isOpen);
                }}
            >
                <Row style={{ alignItems: 'center' }}>
                    <h4>Mã lệnh {data.productNo}</h4>
                    <h4 style={{ color: 'black', marginLeft: '50px' }}>Ngày tạo: {data.workSplitDate}</h4>
                </Row>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: 'auto', flexDirection: 'row' }}>
                    {isEdited && (
                        <Button className={style['submit-button']} color="red" onClick={submitEditYeuCau}>
                            Lưu thay đổi
                        </Button>
                    )}
                    {isEdited && (
                        <Button className={style['cancel-button']} color="red" onClick={revertChange}>
                            Xoá thay đổi
                        </Button>
                    )}
                    <Button className={showPrint ? style['filter-button'] : style['ghost-button']} onClick={onPressPrint}>
                        {showPrint ? 'Xác nhận in' : ' In yêu cầu'}
                    </Button>
                    <Button className={style['filter-button']} onClick={downloadFileYeuCau}>
                        Tải file
                    </Button>
                    {isOpen ? <DownOutlined style={{ color: '#2c3782' }} /> : <RightOutlined style={{ color: '#2c3782' }} />}
                </div>
            </Row>

            {isOpen && (
                <Table
                    pagination={false}
                    columns={columns.filter(c => (showPrint ? true : c.key !== 'isPrint'))}
                    dataSource={tableDataSource}
                    className={style['table']}
                    rowKey={record => record.id}
                    scroll={{ x: tableWidth }}
                />
            )}
            <EditYeuCau ref={modalRef} saveEdit={editDataByBlockId} />
            {contextHolder}
        </div>
    );
};
export default memo(TableYeuCauV2);
