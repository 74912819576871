import store from '../../../../ducks/configStore';

/**
 * build body cho api tạo yêu cầu xẻ băng
 *
 *  api/tape/manufacture-tape
 */
export const buildAddRequest = data => {
    return data.map(block => ({
        id: null,
        workOrderDate: block.workOrderDate,
        lstBlock: block.listRoll.map(roll => ({
            id: null,
            rollId: roll.rollId,
            weight: block.weightAvg,
            md: block.md,
            positionRoll1: block.positionRoll1,
            positionRoll2: block.positionRoll2,
            sizeThickness: block.sizeThickness,
            sizeWidth: block.sizeWidth,
            sizeWidthReal: block.sizeWidthReal,
            type: roll.steelType,
            ts: roll.ts, //nameCustomer
            lstStandard: block.listStandard.map(stand => {
                var numValue = (Number(block.weightAvg) * block.listRoll.length * Number(stand.sizeWidthTape) * Number(stand.stripNumber)) / Number(block.sizeWidthReal);
                return {
                    ...stand,
                    id: undefined,
                    weightCal: isNaN(numValue) || numValue == Infinity ? 0 : numValue.toFixed(2),
                };
            }),
            sizeWidthVia: block.sizeWidthVia,
            totalVia: block.totalVia,
            mass: block.mass,
            ratioTrash: block.ratioTrash,
        })),
    }));
};

/**
 * Transform data của 1 block (1 ngày lệnh) từ api trả về để edit
 * @param {*} block
 * @returns
 */
export const buildEditBlock = block => {
    const { workOrderDate, lstBlock: listRoll, id } = block;
    //console.log('Roll: ', listRoll);
    const listStandard = listRoll[0]?.lstStandard || [];

    const rollMaster = store.getState().app.listRollManufactureIncludeImported || [];

    return {
        id,
        workOrderDate,
        listStandard: listStandard.map((item, i) => ({ ...item })),
        sizeWidth: listRoll[0]?.sizeWidth || '',
        sizeWidthReal: listRoll[0]?.sizeWidthReal || '',
        sizeThickness: listRoll[0]?.sizeThickness || '',
        weightAvg: listRoll[0]?.weight,
        sizeWidthVia: listRoll[0]?.sizeWidthVia || '',
        totalVia: listRoll[0]?.totalVia || '',
        mass: listRoll[0]?.mass || '',
        ratioTrash: listRoll[0]?.ratioTrash || '',
        listRoll: listRoll.map(({ rollId, ts, id }, index) => {
            var roll = rollMaster.find(i => i.id == rollId);
            return {
                rollId,
                id,
                nsmvCoilId: roll.nsmvCoilId,
                importDate: roll.importDate,
                weight: roll.netWeight,
                steelType: roll.steelType,
                steelGrade: roll.steelGrade,
                ts,
                thickWidth: `${listRoll[0]?.sizeThickness || ''}x${listRoll[0]?.sizeWidth || ''}`,
            };
        }),
        md: listRoll[0]?.md || '',
        positionRoll1: listRoll[0]?.positionRoll1 || '',
        positionRoll2: listRoll[0]?.positionRoll2 || '',
    };
};

export const revertEditBlock = block => {
    const {
        workOrderDate,
        listRoll,
        listStandard,
        sizeThickness,
        sizeWidth,
        weightAvg,
        sizeWidthReal,
        sizeWidthVia,
        mass,
        totalVia,
        ratioTrash,
        md,
        positionRoll1,
        positionRoll2,
    } = block;
    return {
        workOrderDate,
        lstBlock: listRoll.map(roll => ({
            id: roll.id,
            rollId: roll.rollId,
            nsmvCoilId: roll.nsmvCoilId,
            weight: weightAvg,
            sizeWidth,
            sizeWidthReal,
            sizeThickness,
            sizeWidthVia,
            mass,
            totalVia,
            ratioTrash,
            type: roll.steelType,
            ts: roll.ts || '',
            md,
            positionRoll1,
            positionRoll2,
            lstStandard: listStandard.map(stand => {
                var numValue = (Number(weightAvg) * listRoll.length * Number(stand.sizeWidthTape) * Number(stand.stripNumber)) / Number(sizeWidthReal);
                return {
                    ...stand,
                    weightCal: isNaN(numValue) || numValue == Infinity ? 0 : numValue.toFixed(2),
                };
            }),
        })),
    };
};
/**
 * Transform data từ api về thành list row trong 1 bảng
 * @param {*} requestObj
 * @returns
 */
export const flattenRequestV2 = requestObj => {
    var tableArr = [];
    console.log('Before transform', requestObj);
    for (const [blockIndex, block] of requestObj.entries()) {
        const { workOrderDate, lstBlock: listRoll, id } = block;
        const listStandard = listRoll[0]?.lstStandard || [];
        const numRow = Math.max(listStandard.length, listRoll.length);

        for (let i = 0; i < numRow; i++) {
            var newRow = {
                id: `block_${blockIndex}-row_${i}`,
                blockId: id,
                rollId: listRoll[i]?.rollId || '',
                nsmvCoilId: listRoll[i]?.nsmvCoilId || '',
                ts: listRoll[i]?.ts || '',
                steelType: listRoll[i]?.type || '',

                ...(i == 0 && {
                    isPrint: block.isPrint,
                    workOrderDate,
                    md: listRoll[i]?.md || '',
                    positionRoll1: listRoll[i]?.positionRoll1 || '',
                    positionRoll2: listRoll[i]?.positionRoll2 || '',
                    sizeThickness: listRoll[i]?.sizeThickness || '',
                    sizeWidth: listRoll[i]?.sizeWidth || '',
                    sizeWidthReal: listRoll[i]?.sizeWidthReal || '',
                    weight: listRoll[i]?.weight || '',
                    numberSplit: listRoll.length,
                    sizeWidthVia: listRoll[i]?.sizeWidthVia || '',
                    totalVia: listRoll[i]?.totalVia || '',
                    mass: listRoll[i]?.mass || '',
                    ratioTrash: listRoll[i]?.ratioTrash || '',
                    showEdit: true,
                }),
                thicknessMin: listStandard[i]?.thicknessMin || '',
                thicknessProduct: listStandard[i]?.thicknessProduct || '',
                sizeWidthTape: listStandard[i]?.sizeWidthTape || '',
                typeProduct: listStandard[i]?.typeProduct || '',
                stripNumber: listStandard[i]?.stripNumber || '',
                totalWidth: listStandard[i]?.totalWidth || '',
                weightCal: listStandard[i]?.weightCal || '',
                note: listStandard[i]?.note,
                orderCode: listStandard[i]?.orderCode || '',
            };
            tableArr.push(newRow);
        }
    }
    //console.log('After transform', tableArr);
    return tableArr;
};

export const buildExcelRequest = data => {
    return data.map(block => {
        var listRoll = block.lstBlock;
        var listStandard = block.lstBlock[0].lstStandard;
        var maxRow = Math.max(listRoll.length, listStandard.length);
        var newBlock = [];

        for (let i = 0; i < maxRow; i++) {
            var nsmvCoilId = !!listRoll[i]?.nsmvCoilId ? listRoll[i].nsmvCoilId.split('_')[1] : '';
            var newRow = {
                rollId: listRoll[i]?.rollId || '',
                nsmvCoilId,
                type: listRoll[i]?.type || '',
                nameCustomer: listRoll[i]?.ts || '',
                positionRoll1: listRoll[i]?.positionRoll1 || '',
                positionRoll2: listRoll[i]?.positionRoll2 || '',

                ...(i == 0 && {
                    md: listRoll[i]?.md || '',
                    numberSplit: listRoll.length,
                    sizeThickness: listRoll[i]?.sizeThickness || '',
                    sizeWidth: listRoll[i]?.sizeWidth || '',
                    sizeWidthReal: listRoll[i]?.sizeWidthReal || '',
                    weight: listRoll[i]?.weight || '',
                }),
                thicknessMin: listStandard[i]?.thicknessMin || '',
                thicknessProduct: listStandard[i]?.thicknessProduct || '',
                sizeWidthTape: listStandard[i]?.sizeWidthTape || '',
                typeProduct: listStandard[i]?.typeProduct || '',
                stripNumber: listStandard[i]?.stripNumber || '',
                totalWidth: listStandard[i]?.totalWidth || '',
                weightCal: listStandard[i]?.weightCal || '',
                ...(i == 0 && {
                    sizeWidthVia: listRoll[i]?.sizeWidthVia || '',
                    totalVia: listRoll[i]?.totalVia || '',
                    mass: listRoll[i]?.mass || '',
                    ratioTrash: listRoll[i]?.ratioTrash || '',
                }),
                note: listStandard[i]?.note,
            };
            var cleanRow = Object.fromEntries(Object.entries(newRow).filter(([key, value]) => value !== ''));
            newBlock.push(cleanRow);
        }
        return { ...block, lstBlock: newBlock };
    });
};
