import { AreaChartOutlined, DropboxOutlined, ExportOutlined, GoldFilled, GoldOutlined, HomeFilled, ImportOutlined, SettingOutlined, WindowsFilled } from "@ant-design/icons";
import {
  BringBottom,
  Bright,
  MoneyBag,
  CommentEdit,
  CameraPlus,
  Channel,
  ChartPie,
  Clipboard,
} from "../../../assets/svg";
import { keys, paths } from "../../../constants";
import { rid } from "../../../helpers";
import i18n, { languageKeys } from "../../../i18n";

/**
 * NOTE: Chức năng phân role (TR, TP) tạm thời bỏ. Nếu khách có yêu cầu phân role thì xoá comment trường roles
 */

const siderBangXe = [
  {
    key: keys.main_tong_quan,
    title: 'Tổng quan',
    icon: <HomeFilled style={{fontSize:20}} />,
    path: paths.main_tong_quan,
    subs: [],
  },
  {
    key: keys.main_danh_sach_ton_kho,
    title: 'Danh sách tồn kho',
    icon: <DropboxOutlined style={{fontSize:20}} />,
    path: paths.main_danh_sach_ton_kho,
    subs: [],
  },
  {
    key: keys.main_nhapkho,
    title: 'Nhập kho',
    icon: <ImportOutlined style={{fontSize:20}} />,
    path: paths.main_nhap_kho,
    subs: [
      {
        key: keys.main_them_moi_nhap_kho,
        title: 'Tạo chỉ thị nhập',
        icon: null,
        path: paths.main_them_moi_nhap_kho,
        subs: [],
      },
      {
        key: keys.main_danh_sach_nhap_kho,
        title: 'Danh sách nhập kho',
        icon: null,
        path: paths.main_danh_sach_nhap_kho,
        subs: [],
      },
      {
        key: keys.main_ds_yeu_cau_xe_bang,
        title: 'Danh sách yêu cầu xẻ băng',
        icon: null,
        path: paths.main_ds_yeu_cau_xe_bang,
        subs: [],
      }
    ],
  },
  {
    key: keys.main_xuat_kho,
    title: 'Xuất kho',
    icon: <ExportOutlined style={{fontSize:20}}/>,
    path: paths.main_xuat_kho,
    subs:  [
      {
        key: keys.main_them_moi_xuat_kho,
        title: 'Tạo chỉ thị xuất',
        icon: null,
        path: paths.main_them_moi_xuat_kho,
        subs: [],
      },
      {
        key: keys.main_danh_sach_chi_thi_xuat_kho,
        title: 'Danh sách chỉ thị xuất kho',
        icon: null,
        path: paths.main_danh_sach_chi_thi_xuat_kho,
        subs: [],
        //roles: [keys.userRoles.KhoTonCuon_PheDuyet]
      },
      // {
      //   key: keys.main_danh_sach_xuat_kho,
      //   title: 'Danh sách xuất kho',
      //   icon: null,
      //   path: paths.main_danh_sach_xuat_kho,
      //   subs: [],
      //   roles: [keys.userRoles.KhoTonCuon_Request]
      // }
    ],
  },
  {
    key: keys.main_kiem_ke,
    title: 'Kiểm kê',
    icon: <GoldOutlined style={{fontSize:20}}/>,
    path: paths.main_chuyen_kho,
    subs: [
      {
        key: keys.main_them_moi_chi_thi_kiem_ke,
        title: 'Tạo chỉ thị kiểm kê',
        icon: null,
        path: paths.main_them_moi_chi_thi_kiem_ke,
        subs: []
      },
      {
        key: keys.main_danh_sach_chi_thi_kiem_ke,
        title: 'Chỉ thị kiểm kê',
        icon: null,
        path: paths.main_danh_sach_chi_thi_kiem_ke,
        subs: []
      }
    ],
  },
  {
    key: keys.main_bao_cao,
    title: 'Báo cáo',
    icon: <AreaChartOutlined style={{fontSize:20}} />,
    path: paths.main_bao_cao,
    subs: [{
      key: keys.main_bao_cao_thong_ke,
      title: 'Thống kê',
      icon: null,
      path: paths.main_bao_cao_thong_ke,
      subs: []
    },
    {
      key: keys.main_bao_cao_xuat_file,
      title: 'Trích xuất dữ liệu',
      icon: null,
      path: paths.main_bao_cao_xuat_file,
      subs: []
    }],
  },
  
  // {
  //   key: keys.main_cai_dat,
  //   title: 'Cài đặt',
  //   icon: <SettingOutlined style={{fontSize:20}} />,
  //   path: paths.main_cai_dat,
  //   subs: [],
  // }
];

const siderTonCuon = [
  {
    key: keys.main_tong_quan,
    title: 'Tổng quan',
    icon: <HomeFilled style={{fontSize:20}} />,
    path: paths.main_tong_quan,
    subs: [],
  },
  {
    key: keys.main_danh_sach_ton_kho,
    title: 'Danh sách tồn kho',
    icon: <DropboxOutlined style={{fontSize:20}} />,
    path: paths.main_danh_sach_ton_kho,
    subs: [],
  },
  {
    key: keys.main_nhapkho,
    title: 'Nhập kho',
    icon: <ImportOutlined style={{fontSize:20}} />,
    path: paths.main_nhap_kho,
    subs: [
      {
        key: keys.main_them_moi_nhap_kho,
        title: 'Tạo chỉ thị nhập',
        icon: null,
        path: paths.main_them_moi_nhap_kho,
        subs: [],
      },
      {
        key: keys.main_danh_sach_nhap_kho,
        title: 'Danh sách nhập kho',
        icon: null,
        path: paths.main_danh_sach_nhap_kho,
        subs: [],
      },

    ],
  },
  {
    key: keys.main_xuat_kho,
    title: 'Xuất kho',
    icon: <ExportOutlined style={{fontSize:20}}/>,
    path: paths.main_xuat_kho,
    subs:  [
      {
        key: keys.main_them_moi_xuat_kho,
        title: 'Tạo chỉ thị xuất',
        icon: null,
        path: paths.main_them_moi_xuat_kho,
        subs: [],
      },
      {
        key: keys.main_danh_sach_chi_thi_xuat_kho,
        title: 'Danh sách chỉ thị xuất kho',
        icon: null,
        path: paths.main_danh_sach_chi_thi_xuat_kho,
        subs: [],
        //roles: [keys.userRoles.KhoTonCuon_PheDuyet]
      },
      // {
      //   key: keys.main_danh_sach_xuat_kho,
      //   title: 'Danh sách xuất kho',
      //   icon: null,
      //   path: paths.main_danh_sach_xuat_kho,
      //   subs: [],
      //   roles: [keys.userRoles.KhoTonCuon_Request]
      // }
    ],
  },
  {
    key: keys.main_kiem_ke,
    title: 'Kiểm kê',
    icon: <GoldOutlined style={{fontSize:20}}/>,
    path: paths.main_chuyen_kho,
    subs: [
      {
        key: keys.main_them_moi_chi_thi_kiem_ke,
        title: 'Tạo chỉ thị kiểm kê',
        icon: null,
        path: paths.main_them_moi_chi_thi_kiem_ke,
        subs: []
      },
      {
        key: keys.main_danh_sach_chi_thi_kiem_ke,
        title: 'Chỉ thị kiểm kê',
        icon: null,
        path: paths.main_danh_sach_chi_thi_kiem_ke,
        subs: []
      }
    ],
  },
  {
    key: keys.main_bao_cao,
    title: 'Báo cáo',
    icon: <AreaChartOutlined style={{fontSize:20}} />,
    path: paths.main_bao_cao,
    subs: [{
      key: keys.main_bao_cao_thong_ke,
      title: 'Thống kê',
      icon: null,
      path: paths.main_bao_cao_thong_ke,
      subs: []
    },
    {
      key: keys.main_bao_cao_xuat_file,
      title: 'Trích xuất dữ liệu',
      icon: null,
      path: paths.main_bao_cao_xuat_file,
      subs: []
    }],
  },
  // {
  //   key: keys.main_cai_dat,
  //   title: 'Cài đặt',
  //   icon: <SettingOutlined style={{fontSize:20}} />,
  //   path: paths.main_cai_dat,
  //   subs: [],
  // }
];

const siderQuanLy = [
  {
    key: keys.main_quan_ly,
    title: 'Quản lý dữ liệu',
    icon:  <SettingOutlined style={{fontSize:20}} />  ,
    path: paths.main_quan_ly,
    subs: [
      {
        key: keys.quy_chuan_bang_xe,
        title: 'Khổ băng xẻ',
        icon: null,
        path: paths.quy_chuan_bang_xe,
        subs: []
      },
      {
        key: keys.quy_chuan_bang_xe_special,
        title: 'Khổ băng xẻ đặc biệt',
        icon: null,
        path: paths.quy_chuan_bang_xe_special,
        subs: []
      }
    ],
  },
]

export {siderBangXe, siderTonCuon, siderQuanLy};
